@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";

.ds-regionspots--variant-1 {
  .ds-regionspot {
    &--mega {
      grid-column: 1 / span 2;

      @media @lg {
        grid-column: 1 / span 4;
      }
    }

    &--medium {
      grid-column: 1 / span 2;

      &.ds-regionspot--5 {
        grid-row: 5;
      }

      @media @lg {
        grid-column: 3 / span 2;

        &.ds-regionspot--5 {
          grid-column: 1 / span 2;
          grid-row: 3;
        }
      }
    }
  }
}

.ds-regionspots--variant-2 {
  .ds-regionspot {
    &--medium {
      grid-column: 1 / span 2;

      &.ds-regionspot--4 {
        grid-row: 4;
      }

      @media @lg {
        grid-column: 3 / span 2;

        &.ds-regionspot--4 {
          grid-column: 1 / span 2;
          grid-row: 2;
        }
      }
    }
  }
}

.ds-regionspots--variant-3 {
  .ds-regionspot--medium {
    grid-column: 1 / span 2;

    @media @lg {
      grid-column: 3 / span 2;
    }
  }
}

.ds-regionspots--variant-4 {
  .ds-regionspot--mega {
    grid-column: 1 / span 2;

    @media @lg {
      grid-column: 1 / span 4;
    }
  }
}