@import (reference) "Mixins/_mixins.less";

.campaign-page {
  .campaigns-header, .campaigns-footer {
    .cm-clearfix();

    .size-full {
      padding: 0 12px;
    }
  }

  .main-column {
    .cm-clearfix();
  }
}

.campaign-page-container {
  padding: 20px 12px 10px;

  .iframe-page & {
    padding: 0;
  }

  .inner-wrapper {
    padding: 30px 18px 10px;
    .theme-background-colors();
    .cm-corners-all(3px);
    .cm-box-shadow();
    .cm-clearfix();

    &.hide-border {
      padding: 0;
    }

    .size-full {
      padding: 0 !important;
    }

    .iframe-page & {
      padding: 0;
      box-shadow: none;
    }
  }

  .image {
    img {
      width: 100%;
      height: auto;
      .cm-corners-top(3px);
      margin-bottom: -3px;
    }
  }

  .content {
    background: #fff;
    padding: 20px 30px;
    .cm-font-size(1.4);

    .teaser {
      .font-size(1.6);
      line-height: 1.3;
      font-weight: bold;
    }

    .content-title {
      font-weight: bold;
      margin-bottom: 8px;
      .font-size(1.5);
      margin-top: 30px;
      font-style: normal;
    }

    .text {
      padding-left: 17px;
    }
    //RTE content
    ul {
      padding-left: 29px;

      li {
        line-height: 18px;
        list-style-type: square;
      }
    }

    ol {
      padding-left: 29px;

      li {
        line-height: 18px;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    .heading {
      .cm-font-ds-black();
      .cm-font(3, 3.5);
    }
    //please wrap this unknown mixing inside and element with a Rule
    .rte-styling();

    .action-section {
      text-align: center;
    }

    &.has-background {
      color: #fff;
      .cm-corners-all(3px);
      .cm-text-shadow(1px, 1px, 3px, rgba(0, 0, 0, 0.50));

      a {
        color: #fff;
      }
    }
  }

  .link-block {
    > .link-block-button-container {
      display: inline-block;
    }

    a {
      .color-bingo-lime & {
        .cm-brand-buttons > .cm-bingo-button-large();
      }

      .color-blitz-bordeaux & {
        .cm-brand-buttons > .cm-danskespil-button-large();
      }

      .color-blitz-white & {
        .cm-brand-buttons > .cm-danskespil-button-large();
      }

      .color-casino-gold & {
        .cm-brand-buttons > .cm-casino-button-large();
      }

      .color-ds-green & {
        .cm-brand-buttons > .cm-danskespil-button-large();
      }

      .color-ds-yellow & {
        .cm-brand-buttons > .cm-danskespil-button-large();
      }

      .color-dantoto-orange & {
        .cm-brand-buttons > .cm-dantoto-button-large();
      }

      .color-eurojackpot-gold & {
        .cm-brand-buttons > .cm-eurojackpot-button-large();
      }

      .color-keno-lightblue & {
        .cm-brand-buttons > .cm-lotto-button-large();
      }

      .color-live-casino-gold & {
        .cm-brand-buttons > .cm-casino-button-large();
      }

      .color-lotto-red & {
        .cm-brand-buttons > .cm-lotto-button-large();
      }

      .color-oddset-darkblue & {
        .cm-brand-buttons > .cm-oddset-button-orange-large();
      }

      .color-oddset-grey & {
        .cm-brand-buttons > .cm-oddset-button-large();
      }

      .color-oddset-orange & {
        .cm-brand-buttons > .cm-oddset-button-large();
      }

      .color-onsdagslotto-blue & {
        .cm-brand-buttons > .cm-lotto-button-large();
      }

      .color-poker-black & {
        .cm-brand-buttons > .cm-poker-button-large();
      }

      .color-quick-violet & {
        .cm-brand-buttons > .cm-quick-button-large();
      }

      .color-tips-green & {
        .cm-brand-buttons > .cm-tips-button-large();
      }
    }
  }
}
