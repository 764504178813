@import (reference) "../variables.less";

body.region-poker {
  .seo-text-container,
  footer .info-bar {
    background: @poker-background;
  }

  footer .navigation-box .navigation-item-link:hover {
    color: @ds-white;
  }
}
