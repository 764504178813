﻿@import (reference) "./../../../_imports";
@import (reference) "../Colors/_colors";

.mediahub-container() {
  padding: 0 1.5rem;
  max-width: 102.4rem;
  margin: 0 auto 3rem;

  @media @xxl {
    max-width: 128rem;
    margin: 0 auto 5rem;
  }
}

.mediahub-article-container() {
  padding: 0 1.5rem;
  max-width: 57rem;
  margin: 0 auto;

  @media @sm {
    padding: 0;
    max-width: 60rem;
  }
  
  @media @xxl {
    max-width: 64rem;
  }
}
