/* ===========================================
   component.game-gallery
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
.poker-game-gallery {
  background: #000000;
  color: #ffffff;
  width: 100%;
}
.poker-game-gallery__wrapper {
  display: flex;
  flex-flow: row wrap;
}
.poker-game-gallery__item {
  display: flex;
  flex-direction: column;
  flex: 1 0 25%;
  flex-basis: calc(100%/4 - (2rem / 2));
}
.poker-game-gallery__item-image {
  flex: 0 0 auto;
}
.poker-game-gallery__item-image img {
  max-width: 100%;
  width: 100%;
  height: auto;
  display: block;
}
.poker-game-gallery__item-text {
  margin: 1rem 0;
  margin-top: 0.2rem;
  text-align: center;
}
.poker-game-gallery__item-cta {
  text-align: center;
  margin: auto auto 0;
}
.poker-game-gallery__item-btn {
  margin: 0;
  min-width: 10rem;
}
.poker-game-gallery__game-info {
  margin-bottom: 0.5rem;
  text-align: center;
}
.poker-game-gallery__game-info-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.poker-game-gallery__game-info-jackpot {
  font-size: 1.3rem;
}
.poker-game-gallery.items-per-row__3 .poker-game-gallery__item {
  flex: 1 0 33.33%;
  flex-basis: calc(100%/3 - (2rem * 3) / 4);
}
@media screen and (min-width: 767px) {
  .poker-game-gallery.items-per-row__3 .poker-game-gallery__item:not(:nth-child(3n + 1)) {
    margin-left: 2rem;
  }
  .poker-game-gallery.items-per-row__3 .poker-game-gallery__item:nth-child(1n + 4) {
    margin-top: 2rem;
  }
}
.poker-game-gallery.items-per-row__4 .poker-game-gallery__item {
  flex: 0 0 25%;
  flex-basis: calc(100%/4 - (2rem * 3 / 4));
}
@media screen and (min-width: 767px) {
  .poker-game-gallery.items-per-row__4 .poker-game-gallery__item:not(:nth-child(4n + 1)) {
    margin-left: 2rem;
  }
  .poker-game-gallery.items-per-row__4 .poker-game-gallery__item:nth-child(1n + 5) {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .poker-game-gallery.items-per-row__3 .poker-game-gallery__item,
  .poker-game-gallery.items-per-row__4 .poker-game-gallery__item {
    flex-basis: calc(100% / 2 - (2rem / 2));
  }
  .poker-game-gallery.items-per-row__3 .poker-game-gallery__item:nth-child(even),
  .poker-game-gallery.items-per-row__4 .poker-game-gallery__item:nth-child(even) {
    margin-left: 2rem;
  }
  .poker-game-gallery.items-per-row__3 .poker-game-gallery__item:nth-child(1n + 3),
  .poker-game-gallery.items-per-row__4 .poker-game-gallery__item:nth-child(1n + 3) {
    margin-top: 2rem;
  }
}
