@import (reference) "./_imports";

.mediahub-article-image {
  .mediahub-article-container();
  margin-bottom: 3rem;
  
  img {
    border: none;
    margin: 0 0 1rem;
    width: 100%;
    height: auto;
  }
}

.mediahub-article-image__caption {
  .mediahub-base-font(1.2rem);
}
