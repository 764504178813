.mediahub-navbar {
  display: flex;
  align-items: center;
  height: 5.6rem;
  max-width: 102.4rem;
  margin: 0 auto 1rem;
  padding: 0 0.5rem;
}
@media screen and (min-width: 1440px) {
  .mediahub-navbar {
    max-width: 128rem;
    margin-bottom: 3rem;
  }
}
.mediahub-navbar__link {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4;
  padding: 1rem 1.25rem;
  color: #e8e8e8;
}
@media screen and (min-width: 1216px) {
  .mediahub-navbar__link {
    padding: 1rem 1.25rem;
    margin-right: 5.5rem;
  }
}
.mediahub-navbar__link::after {
  content: "";
  position: absolute;
  left: 1rem;
  bottom: 0.5rem;
  height: 0.2rem;
  background-color: white;
  border-radius: 0.1rem;
  right: 0.7rem;
  width: 0;
  transition: 200ms ease-out;
  pointer-events: none;
}
.mediahub-navbar__link::before {
  content: attr(data-text);
  display: block;
  font-weight: bold;
  height: 0.1rem;
  color: transparent;
  overflow: hidden;
  visibility: hidden;
  pointer-events: none;
}
.mediahub-navbar__link--selected {
  color: white;
  font-weight: bold;
}
.mediahub-navbar__link--selected::after {
  width: calc(60% - 1rem);
}
.mediahub-navbar--link-hover .mediahub-navbar__link--selected {
  font-weight: normal;
}
.mediahub-navbar--link-hover .mediahub-navbar__link--selected::after {
  width: 0;
}
.mediahub-navbar__link:hover {
  color: white;
  font-weight: bold;
}
.mediahub-navbar__link:hover::after {
  width: calc(60% - 1rem);
}
@media screen and (min-width: 1216px) {
  .mediahub-navbar__link {
    font-size: 1.6rem;
  }
}
.mediahub-navbar-backlink {
  background-repeat: no-repeat;
  background-position: left center;
  margin-left: 1.25rem;
  padding: 0.5rem 1rem 0.5rem 3rem;
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/arrow-back-small.svg);
  font-size: 1.4rem;
  color: #E9E8EB;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
}
@media screen and (min-width: 1216px) {
  .mediahub-navbar-backlink {
    padding-left: 6rem;
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/arrow-back.svg);
  }
}
.mediahub-navbar-backlink--hidden {
  display: none;
}
