﻿@import (reference) "./_imports";

.mediahub-navbar {
  display: flex;
  align-items: center;
  height: 5.6rem;
  max-width: 102.4rem;
  margin: 0 auto 1rem;
  padding: 0 0.5rem;

  @media @xxl {
    max-width: 128rem;
    margin-bottom: 3rem;
  }
}

.mediahub-navbar__link {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4;
  padding: 1rem 1.25rem;
  color: @ds-gray-125;

  @media @xl {
    padding: 1rem 1.25rem;
    margin-right: 5.5rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
    height: 0.2rem;
    background-color: @ds-white;
    border-radius: 0.1rem;
    right: 0.7rem;
    width: 0;
    transition: 200ms ease-out;
    pointer-events: none;
  }

  &::before {
    // Hack, to prevent item container being shrinked when font weight is normalized.
    content: attr(data-text);
    display: block;
    font-weight: bold;
    height: 0.1rem;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;
  }

  &--selected {
    color: @ds-white;
    font-weight: bold;

    &::after {
      width: ~"calc(60% - 1rem)";
    }
  }

  .mediahub-navbar--link-hover &--selected {
    font-weight: normal;

    &::after {
      width: 0;
    }
  }

  &:hover {
    color: @ds-white;
    font-weight: bold;

    &::after {
      width: ~"calc(60% - 1rem)";
    }
  }

  @media @xl {
    font-size: 1.6rem;
  }
}

.mediahub-navbar-backlink {
  background-repeat: no-repeat;
  background-position: left center;
  margin-left: 1.25rem;
  padding: 0.5rem 1rem 0.5rem 3rem;
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/arrow-back-small.svg);
  font-size: 1.4rem;
  color: @mediahub-color-lightgrey;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;

  @media @xl {
    padding-left: 6rem;
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/arrow-back.svg);
  }

  &--hidden {
    // Hidden until JS have figured out if the link and text should be changed.
    // We don't want the user to see one text, and then another when the page has done loading.
    display: none;
  }
}
