.mediahub-download-app {
  padding: 0 1.5rem;
  max-width: 102.4rem;
  margin: 0 auto 3rem;
  padding: 2rem 4rem;
  margin: 4rem auto 3rem;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "description" "image" "buttons";
  position: relative;
}
@media screen and (min-width: 1440px) {
  .mediahub-download-app {
    max-width: 128rem;
    margin: 0 auto 5rem;
  }
}
.mediahub-download-app::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 1.5rem;
  right: 1.5rem;
  top: 0;
  bottom: 0;
  background-color: #1C1A21;
  border-radius: 1rem;
}
.mediahub-download-app:hover::after {
  background-color: #373341;
}
@media screen and (min-width: 1024px) {
  .mediahub-download-app {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "description image buttons";
    margin: 6rem auto 4rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-download-app {
    margin: 8rem auto 6rem;
    padding: 2rem 6rem;
  }
}
.mediahub-download-app__description {
  grid-area: description;
}
.mediahub-download-app__title {
  text-align: center;
}
.mediahub-download-app__title,
h1.mediahub-download-app__title,
h2.mediahub-download-app__title,
h3.mediahub-download-app__title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 1.5;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 0.5rem;
}
h1.mediahub-download-app__title {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 1024px) {
  .mediahub-download-app__title {
    text-align: left;
  }
}
.mediahub-download-app__text {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  text-align: center;
  padding-bottom: 2rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-download-app__text {
    text-align: left;
    padding-bottom: 0;
  }
}
.mediahub-download-app__image {
  grid-area: image;
}
.mediahub-download-app__image .mediahub-download-app__image-prefix {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  text-align: center;
  padding-bottom: 1rem;
}
.mediahub-download-app__image img {
  display: block;
  height: 5.7rem;
  margin: 0 auto 2rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-download-app__image img {
    margin-bottom: 0;
  }
}
.mediahub-download-app__buttons {
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-download-app__buttons {
    justify-content: flex-end;
  }
}
.mediahub-download-app__cta {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #000000;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;
}
.mediahub-download-app__cta::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 2rem;
  transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
}
.mediahub-download-app__cta:hover::before {
  left: -0.5rem;
  right: -0.5rem;
  background-color: #E9E8EB;
}
.mediahub-download-app__cta--download {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #000000;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;
  width: auto;
}
.mediahub-download-app__cta--download::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 2rem;
  transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
}
.mediahub-download-app__cta--download:hover::before {
  left: -0.5rem;
  right: -0.5rem;
  background-color: #E9E8EB;
}
.mediahub-download-app__cta--download::before {
  background-color: #FEB700;
}
.mediahub-download-app__cta--download:hover::before {
  background-color: #eaa800;
}
