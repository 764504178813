/* ===========================================
   component.game-gallery
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import "_imports.less";

.poker-game-gallery {
  background: @color-black-100;
  color: @color-white-100;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  &__item {
    display: flex;
    flex-direction: column;
    flex: 1 0 25%;
    flex-basis: calc(~'100%/4 - (@{item-margin} / 2)');
  }

  &__item-image {
    flex: 0 0 auto;

    img {
      max-width: 100%;
      width: 100%;
      height: auto;
      display: block;
    }
  }

  &__item-text {
    margin: @item-margin / 2 0;
    margin-top: 0.2rem;
    text-align: center;
  }

  &__item-cta {
    text-align: center;
    margin: auto auto 0;
  }

  &__item-btn {
    margin: 0;
    min-width: 10rem;
  }

  &__game-info {
    margin-bottom:0.5rem;
    text-align:center;

    &-title {
      font-size:1.5rem; 
      font-weight:bold;
    }
    &-jackpot {
      font-size:1.3rem;
    }
  }

  &.items-per-row {
    &__3 {
      .poker-game-gallery__item {
        flex: 1 0 33.33%;
        flex-basis: calc(~'100%/3 - (@{item-margin} * 3) / 4');

        @media screen and (min-width: @medium) {
          &:not(:nth-child(3n + 1)) {
            margin-left: @item-margin;
          }
          &:nth-child(1n + 4) {
            margin-top: @item-margin;
          }
        }
      }
    }
    
    &__4 {
      .poker-game-gallery__item {
        flex: 0 0 25%;
        flex-basis: calc(~'100%/4 - (@{item-margin} * 3 / 4)');

        @media screen and (min-width: @medium) {
          &:not(:nth-child(4n + 1)){
            margin-left: @item-margin;
          }
          
          &:nth-child(1n + 5) {
            margin-top: @item-margin;
          }
        }
      }
    }
    
    &__3,
    &__4 {
      .poker-game-gallery__item {
        @media screen and (max-width: @medium) {
          flex-basis: calc(~'100% / 2 - (@{item-margin} / 2)');

          &:nth-child(even) {
            margin-left: @item-margin;
          }
          
          &:nth-child(1n + 3) {
            margin-top: @item-margin;
          }
        }
      }
    }
  }
}
