.mediahub-article-image {
  padding: 0 1.5rem;
  max-width: 57rem;
  margin: 0 auto;
  margin-bottom: 3rem;
}
@media screen and (min-width: 768px) {
  .mediahub-article-image {
    padding: 0;
    max-width: 60rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-article-image {
    max-width: 64rem;
  }
}
.mediahub-article-image img {
  border: none;
  margin: 0 0 1rem;
  width: 100%;
  height: auto;
}
.mediahub-article-image__caption {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: white;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}
