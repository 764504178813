.mediahub-series-highlight {
  padding: 0 1.5rem;
  max-width: 102.4rem;
  margin: 0 auto 3rem;
  padding: 0;
}
@media screen and (min-width: 1440px) {
  .mediahub-series-highlight {
    max-width: 128rem;
    margin: 0 auto 5rem;
  }
}
.mediahub-series-highlight__title {
  padding: 0 1.5rem;
}
.mediahub-series-highlight__title,
h1.mediahub-series-highlight__title,
h2.mediahub-series-highlight__title,
h3.mediahub-series-highlight__title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 1.5;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 1rem;
}
h1.mediahub-series-highlight__title {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__title,
  h1.mediahub-series-highlight__title,
  h2.mediahub-series-highlight__title,
  h3.mediahub-series-highlight__title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 2.4rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 2rem;
  }
  h1.mediahub-series-highlight__title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-series-highlight__title--show-more {
  display: none;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  line-height: 4.4rem;
  font-weight: 400;
  float: right;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__title--show-more {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight[data-episodes-in-series="0"] .mediahub-series-highlight__title--show-more,
  .mediahub-series-highlight[data-episodes-in-series="1"] .mediahub-series-highlight__title--show-more,
  .mediahub-series-highlight[data-episodes-in-series="2"] .mediahub-series-highlight__title--show-more,
  .mediahub-series-highlight[data-episodes-in-series="3"] .mediahub-series-highlight__title--show-more,
  .mediahub-series-highlight[data-episodes-in-series="4"] .mediahub-series-highlight__title--show-more {
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-series-highlight[data-episodes-in-series="0"] .mediahub-series-highlight__title--show-more,
  .mediahub-series-highlight[data-episodes-in-series="1"] .mediahub-series-highlight__title--show-more,
  .mediahub-series-highlight[data-episodes-in-series="2"] .mediahub-series-highlight__title--show-more,
  .mediahub-series-highlight[data-episodes-in-series="3"] .mediahub-series-highlight__title--show-more,
  .mediahub-series-highlight[data-episodes-in-series="4"] .mediahub-series-highlight__title--show-more,
  .mediahub-series-highlight[data-episodes-in-series="5"] .mediahub-series-highlight__title--show-more {
    display: none;
  }
}
.mediahub-series-highlight__contents {
  white-space: nowrap;
  overflow: hidden;
  height: 17rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__contents {
    overflow: hidden;
    height: auto;
    white-space: normal;
  }
}
.mediahub-series-highlight__contents-inner {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 1rem 5rem 1.5rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__contents-inner {
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
    display: grid;
    grid-template-columns: repeat(4, 23.5rem);
    grid-template-rows: repeat(auto-fill, 35.9rem);
    gap: calc((100% - 4 * 23.5rem) / 3);
    padding: 0 1.5rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-series-highlight__contents-inner {
    grid-template-columns: repeat(5, 23.5rem);
    gap: calc((100% - 5 * 23.5rem) / 4);
  }
}
.mediahub-series-highlight__episode {
  white-space: normal;
  position: relative;
  display: inline-grid;
  margin-right: 1rem;
  grid-template-columns: 14.5rem;
  grid-template-rows: 14.5rem 1fr;
  row-gap: 0.5rem;
  grid-template-areas: "card" "title";
}
.mediahub-series-highlight__episode:hover {
  background-color: #373341;
}
.mediahub-series-highlight__episode:hover .mediahub-series-highlight__episode-card :first-child {
  transform: scale(96%);
}
.mediahub-series-highlight__episode:hover .mediahub-series-highlight__episode-card :last-child {
  background-size: auto 103%;
}
@media screen and (min-width: 768px) {
  .mediahub-series-highlight__episode {
    grid-template-rows: 14.5rem 2.7rem;
    row-gap: 0.8rem;
  }
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__episode {
    height: 35.9rem;
    padding: 1.75rem 1.75rem 2rem;
    margin-right: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 21rem auto 1fr;
    grid-template-areas: "card card" "title title" "date duration";
    background-color: #2D2A35;
    border-radius: 1rem;
    overflow: hidden;
  }
  .mediahub-series-highlight__episode:nth-child(1n + 5) {
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-series-highlight__episode:nth-child(1n + 5) {
    display: inline-grid;
  }
  .mediahub-series-highlight__episode:nth-child(1n + 6) {
    display: none;
  }
}
.mediahub-series-highlight__episode-show-more {
  position: relative;
  height: 14.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #373341;
}
.mediahub-series-highlight__episode-show-more[data-has-eight-or-less-episodes] {
  display: none;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__episode-show-more {
    display: none;
  }
}
.mediahub-series-highlight__episode-show-more-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mediahub-series-highlight__episode-show-more-inner,
h1.mediahub-series-highlight__episode-show-more-inner,
h2.mediahub-series-highlight__episode-show-more-inner,
h3.mediahub-series-highlight__episode-show-more-inner {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 1.5;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: initial;
}
h1.mediahub-series-highlight__episode-show-more-inner {
  font-weight: 800;
  color: white;
}
.mediahub-series-highlight__episode-show-more-inner::after {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 0.8rem;
  line-height: 1;
  background-repeat: no-repeat;
  background-position: left center;
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/arrow-show-more.svg);
}
h3.mediahub-series-highlight__episode-title {
  grid-area: title;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: white;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  height: auto;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0 0.2rem;
}
@media screen and (min-width: 1024px) {
  h3.mediahub-series-highlight__episode-title {
    height: auto;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding: 0;
  }
  h3.mediahub-series-highlight__episode-title,
  h1h3.mediahub-series-highlight__episode-title,
  h2h3.mediahub-series-highlight__episode-title,
  h3h3.mediahub-series-highlight__episode-title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1h3.mediahub-series-highlight__episode-title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-series-highlight__episode-text {
  display: none;
}
.mediahub-series-highlight__episode-date {
  display: none;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__episode-date {
    grid-area: date;
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
    display: flex;
    align-items: flex-end;
  }
}
.mediahub-series-highlight__episode-duration {
  grid-area: duration;
  display: none;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__episode-duration {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
.mediahub-series-highlight__episode-cta {
  display: none;
}
.mediahub-series-highlight__episode-card {
  background-color: #222222;
  border-radius: 0.5rem;
  overflow: hidden;
  grid-area: card;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.mediahub-series-highlight__episode-card-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: transform 200ms ease-out;
}
.mediahub-series-highlight__episode-card-bg {
  position: relative;
  background-position: center;
  background-size: auto 100%;
  transition: background-size 300ms ease-out;
  width: 100%;
  height: 100%;
}
.mediahub-series-highlight__episode-card-bg::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mediahub-series-highlight__episode--article .mediahub-series-highlight__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__episode--article .mediahub-series-highlight__episode-card-bg,
  h1.mediahub-series-highlight__episode--article .mediahub-series-highlight__episode-card-bg,
  h2.mediahub-series-highlight__episode--article .mediahub-series-highlight__episode-card-bg,
  h3.mediahub-series-highlight__episode--article .mediahub-series-highlight__episode-card-bg {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1.mediahub-series-highlight__episode--article .mediahub-series-highlight__episode-card-bg {
    font-weight: 800;
    color: white;
  }
  .mediahub-series-highlight__episode--article .mediahub-series-highlight__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 3.6rem;
    content: "artikel";
  }
}
.mediahub-series-highlight__episode--podcast .mediahub-series-highlight__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__episode--podcast .mediahub-series-highlight__episode-card-bg,
  h1.mediahub-series-highlight__episode--podcast .mediahub-series-highlight__episode-card-bg,
  h2.mediahub-series-highlight__episode--podcast .mediahub-series-highlight__episode-card-bg,
  h3.mediahub-series-highlight__episode--podcast .mediahub-series-highlight__episode-card-bg {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1.mediahub-series-highlight__episode--podcast .mediahub-series-highlight__episode-card-bg {
    font-weight: 800;
    color: white;
  }
  .mediahub-series-highlight__episode--podcast .mediahub-series-highlight__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 3.3rem;
    content: "podcast";
  }
}
.mediahub-series-highlight__episode--video .mediahub-series-highlight__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-highlight__episode--video .mediahub-series-highlight__episode-card-bg,
  h1.mediahub-series-highlight__episode--video .mediahub-series-highlight__episode-card-bg,
  h2.mediahub-series-highlight__episode--video .mediahub-series-highlight__episode-card-bg,
  h3.mediahub-series-highlight__episode--video .mediahub-series-highlight__episode-card-bg {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1.mediahub-series-highlight__episode--video .mediahub-series-highlight__episode-card-bg {
    font-weight: 800;
    color: white;
  }
  .mediahub-series-highlight__episode--video .mediahub-series-highlight__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 4.2rem;
    content: "video";
  }
}
