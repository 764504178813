.mediahub-series-content {
  padding: 0 1.5rem;
  max-width: 102.4rem;
  margin: 0 auto 3rem;
}
@media screen and (min-width: 1440px) {
  .mediahub-series-content {
    max-width: 128rem;
    margin: 0 auto 5rem;
  }
}
.mediahub-series-content__title,
h1.mediahub-series-content__title,
h2.mediahub-series-content__title,
h3.mediahub-series-content__title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 1.5;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 2rem;
}
h1.mediahub-series-content__title {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 1216px) {
  .mediahub-series-content__title,
  h1.mediahub-series-content__title,
  h2.mediahub-series-content__title,
  h3.mediahub-series-content__title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 3.2rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 2rem;
  }
  h1.mediahub-series-content__title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-series-content__episode-id {
  position: absolute;
  z-index: 10000;
  top: -7rem;
  left: 0;
  width: 0;
  height: 0;
}
@media screen and (min-width: 950px) {
  .mediahub-series-content__episode-id {
    top: -15rem;
  }
}
.mediahub-series-content__episode {
  padding: 0 0 3rem;
  margin-bottom: 1rem;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-areas: "title title title" "text text text" "readmore readmore readmore" "participants participants participants" "date duration cta";
}
@media screen and (min-width: 1216px) {
  .mediahub-series-content__episode {
    padding-left: 15rem;
    min-height: 14.5rem;
  }
}
.mediahub-series-content__episode::after {
  pointer-events: none;
  content: "";
  position: absolute;
  z-index: -2;
  left: 0;
  right: 0;
  top: -2.5rem;
  border-top: 0.1rem solid #373341;
}
.mediahub-series-content__title + .mediahub-series-content__episode::after {
  border-top: none;
}
.mediahub-series-content__episode:hover::after {
  z-index: -1;
  top: -1rem;
  bottom: 1.5rem;
  left: -1rem;
  right: -1rem;
  border-radius: 1rem;
  border-top: none;
  background-color: #1C1A21;
}
.mediahub-series-content__episode:last-child::before {
  pointer-events: none;
  content: "";
  position: absolute;
  z-index: -2;
  left: 0;
  right: 0;
  bottom: 1rem;
  border-bottom: 0.1rem solid #373341;
}
.mediahub-series-content__episode:last-child:hover::before {
  border-bottom: none;
}
.mediahub-series-content__episode:hover .mediahub-series-content__episode-card :first-child {
  transform: scale(96%);
}
.mediahub-series-content__episode:hover .mediahub-series-content__episode-card :last-child {
  background-size: auto 103%;
}
.mediahub-series-content__episode:hover + .mediahub-series-content__episode::after {
  border-top: none;
}
.mediahub-series-content__episode-title {
  grid-area: title;
  height: auto;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-left: 5rem;
  min-height: 5rem;
  border-bottom: 1rem solid transparent;
}
.mediahub-series-content__episode-title,
h1.mediahub-series-content__episode-title,
h2.mediahub-series-content__episode-title,
h3.mediahub-series-content__episode-title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 1.5;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 0.3rem;
}
h1.mediahub-series-content__episode-title {
  font-weight: 800;
  color: white;
}
.mediahub-series-content__episode--selected .mediahub-series-content__episode-title {
  color: #FEB700;
}
@media screen and (min-width: 1216px) {
  .mediahub-series-content__episode-title {
    padding-left: 0;
    min-height: initial;
    border-bottom: none;
  }
}
.mediahub-series-content__episode-text {
  grid-area: text;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  height: auto;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.mediahub-series-content__episode--open-read-more .mediahub-series-content__episode-text {
  overflow: auto;
  max-height: auto;
  text-overflow: initial;
  display: block;
}
@media screen and (min-width: 1216px) {
  .mediahub-series-content__episode-text {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.6rem;
    color: #E9E8EB;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
    height: auto;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.mediahub-series-content__episode-read-more {
  grid-area: readmore;
  display: none;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  margin-right: auto;
  cursor: pointer;
  text-decoration: underline;
  position: relative;
  padding: 0.7rem 2rem 0 0;
}
@media screen and (min-width: 1216px) {
  .mediahub-series-content__episode-read-more {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #E9E8EB;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-series-content__episode-read-more::before {
  content: "Vis mere";
  cursor: pointer;
}
.mediahub-series-content__episode-read-more::after {
  content: "";
  position: absolute;
  position: relative;
  left: 1.3ch;
  top: -0.2em;
  border: solid #E9E8EB;
  border-width: 0 0.1rem 0.1rem 0;
  display: inline-block;
  padding: 0.2rem;
  transform: rotate(45deg);
}
.mediahub-series-content__episode--show-read-more .mediahub-series-content__episode-read-more {
  display: block;
}
.mediahub-series-content__episode--show-read-more.mediahub-series-content__episode--open-read-more .mediahub-series-content__episode-read-more::before {
  content: "Vis mindre";
}
.mediahub-series-content__episode--show-read-more.mediahub-series-content__episode--open-read-more .mediahub-series-content__episode-read-more::after {
  top: 0;
  transform: rotate(225deg);
}
.mediahub-series-content__episode-participants {
  grid-area: participants;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  padding: 1.5rem 0 0.5rem;
  margin-right: 9rem;
}
.mediahub-series-content__episode-participants a,
.mediahub-series-content__episode-participants a:not([class]) {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}
@media screen and (min-width: 1216px) {
  .mediahub-series-content__episode-participants {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
  .mediahub-series-content__episode-participants a,
  .mediahub-series-content__episode-participants a:not([class]) {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-series-content__episode-date {
  grid-area: date;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.9rem;
}
@media screen and (min-width: 1216px) {
  .mediahub-series-content__episode-date {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-series-content__episode-duration {
  grid-area: duration;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.9rem;
  padding-left: 2rem;
}
@media screen and (min-width: 1216px) {
  .mediahub-series-content__episode-duration {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-series-content__episode-cta {
  grid-area: cta;
  margin-top: auto;
}
.mediahub-series-content__episode--article .mediahub-series-content__episode-cta {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #000000;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;
}
.mediahub-series-content__episode--article .mediahub-series-content__episode-cta::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 2rem;
  transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
}
.mediahub-series-content__episode--article .mediahub-series-content__episode-cta:hover::before {
  left: -0.5rem;
  right: -0.5rem;
  background-color: #E9E8EB;
}
.mediahub-series-content__episode--article .mediahub-series-content__episode-cta::after {
  content: "";
  margin-left: 0.7rem;
  width: 0.8rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
}
.mediahub-series-content__episode--article .mediahub-series-content__episode-cta::after {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-read.svg);
}
.mediahub-series-content__episode--podcast .mediahub-series-content__episode-cta {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #000000;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;
}
.mediahub-series-content__episode--podcast .mediahub-series-content__episode-cta::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 2rem;
  transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
}
.mediahub-series-content__episode--podcast .mediahub-series-content__episode-cta:hover::before {
  left: -0.5rem;
  right: -0.5rem;
  background-color: #E9E8EB;
}
.mediahub-series-content__episode--podcast .mediahub-series-content__episode-cta::after {
  content: "";
  margin-left: 0.7rem;
  width: 0.8rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
}
.mediahub-series-content__episode--podcast .mediahub-series-content__episode-cta::after {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-listen.svg);
}
.mediahub-series-content__episode--video .mediahub-series-content__episode-cta {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #000000;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;
}
.mediahub-series-content__episode--video .mediahub-series-content__episode-cta::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 2rem;
  transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
}
.mediahub-series-content__episode--video .mediahub-series-content__episode-cta:hover::before {
  left: -0.5rem;
  right: -0.5rem;
  background-color: #E9E8EB;
}
.mediahub-series-content__episode--video .mediahub-series-content__episode-cta::after {
  content: "";
  margin-left: 0.7rem;
  width: 0.8rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
}
.mediahub-series-content__episode--video .mediahub-series-content__episode-cta::after {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-play.svg);
}
.mediahub-series-content__episode-card {
  background-color: #222222;
  border-radius: 0.5rem;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;
}
@media screen and (min-width: 1216px) {
  .mediahub-series-content__episode-card {
    width: 12rem;
    height: 12rem;
  }
}
.mediahub-series-content__episode-card-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: transform 200ms ease-out;
}
.mediahub-series-content__episode-card-bg {
  position: relative;
  background-position: center;
  background-size: auto 100%;
  transition: background-size 300ms ease-out;
  width: 100%;
  height: 100%;
}
.mediahub-series-content__episode-card-bg::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mediahub-series-content__episode--article .mediahub-series-content__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
  background-position: 0.5rem 0.5rem;
  background-size: auto 1rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-content__episode--article .mediahub-series-content__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
    background-position: 0.9rem 0.9rem;
    background-size: auto 1.5rem;
  }
}
.mediahub-series-content__episode--podcast .mediahub-series-content__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
  background-position: 0.5rem 0.5rem;
  background-size: auto 1rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-content__episode--podcast .mediahub-series-content__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
    background-position: 0.9rem 0.9rem;
    background-size: auto 1.5rem;
  }
}
.mediahub-series-content__episode--video .mediahub-series-content__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-position: 0.5rem 0.5rem;
  background-size: auto 1rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-series-content__episode--video .mediahub-series-content__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
    background-position: 0.9rem 0.9rem;
    background-size: auto 1.5rem;
  }
}
.mediahub-series-content-about {
  padding: 0 1.5rem;
  max-width: 102.4rem;
  margin: 0 auto 3rem;
  position: relative;
}
@media screen and (min-width: 1440px) {
  .mediahub-series-content-about {
    max-width: 128rem;
    margin: 0 auto 5rem;
  }
}
.mediahub-series-content-about__title {
  grid-area: title;
  padding-left: 16.5rem;
  min-height: 16.5rem;
}
.mediahub-series-content-about__title,
h1.mediahub-series-content-about__title,
h2.mediahub-series-content-about__title,
h3.mediahub-series-content-about__title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 3.2rem;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 0.3rem;
}
h1.mediahub-series-content-about__title {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 768px) {
  .mediahub-series-content-about__title,
  h1.mediahub-series-content-about__title,
  h2.mediahub-series-content-about__title,
  h3.mediahub-series-content-about__title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 5rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 6rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 0.3rem;
  }
  h1.mediahub-series-content-about__title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-series-content-about__text {
  grid-area: text;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}
.mediahub-series-content-about__card {
  background-color: #222222;
  border-radius: 0.5rem;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 1.5rem;
  width: 15rem;
  height: 15rem;
}
.mediahub-series-content-about__card-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: transform 200ms ease-out;
}
.mediahub-series-content-about__card-bg {
  position: relative;
  background-position: center;
  background-size: auto 100%;
  transition: background-size 300ms ease-out;
  width: 100%;
  height: 100%;
}
.mediahub-series-content-about__card-bg::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mediahub-series-content-about--article .mediahub-series-content-about__card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
.mediahub-series-content-about--podcast .mediahub-series-content-about__card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
.mediahub-series-content-about--video .mediahub-series-content-about__card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
.mediahub-series-content-about--article.mediahub-series-content-about--podcast .mediahub-series-content-about__card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
  background-size: auto 1.5rem;
  background-position: 0.8rem 0.8rem, 2.6rem 0.8rem;
}
.mediahub-series-content-about--article.mediahub-series-content-about--video .mediahub-series-content-about__card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-size: auto 1.5rem;
  background-position: 0.8rem 0.8rem, 2.6rem 0.8rem;
}
.mediahub-series-content-about--podcast.mediahub-series-content-about--video .mediahub-series-content-about__card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-size: auto 1.5rem;
  background-position: 0.8rem 0.8rem, 2.6rem 0.8rem;
}
.mediahub-series-content-about--article.mediahub-series-content-about--podcast.mediahub-series-content-about--video .mediahub-series-content-about__card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-size: auto 1.5rem;
  background-position: 0.8rem 0.8rem, 2.6rem 0.8rem, 4.2rem 0.8rem;
}
