html.mode-edit .ds-regionspots .scEmptyPlaceholder {
  height: 100%;
}
html.mode-edit .ds-regionspots > div {
  position: relative;
}
html.mode-edit .ds-regionspots > div::after {
  pointer-events: none;
  position: absolute;
  top: 0.7rem;
  left: 0.7rem;
  z-index: 1;
  font-size: 1.1rem;
  color: #000000;
  background: rgba(255, 255, 255, 0.8);
  padding: 0.4rem 0.5rem;
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-1 > div:nth-of-type(1)::after {
  content: "Regionspot 1: Mega";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-1 > div:nth-of-type(2)::after {
  content: "Regionspot 2: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-1 > div:nth-of-type(3)::after {
  content: "Regionspot 3: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-1 > div:nth-of-type(4)::after {
  content: "Regionspot 4: Medium";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-1 > div:nth-of-type(5)::after {
  content: "Regionspot 5: Medium";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-1 > div:nth-of-type(6)::after {
  content: "Regionspot 6: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-1 > div:nth-of-type(7)::after {
  content: "Regionspot 7: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-2 > div:nth-of-type(1)::after {
  content: "Regionspot 1: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-2 > div:nth-of-type(2)::after {
  content: "Regionspot 2: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-2 > div:nth-of-type(3)::after {
  content: "Regionspot 3: Medium";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-2 > div:nth-of-type(4)::after {
  content: "Regionspot 4: Medium";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-2 > div:nth-of-type(5)::after {
  content: "Regionspot 5: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-2 > div:nth-of-type(6)::after {
  content: "Regionspot 6: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-3 > div:nth-of-type(1)::after {
  content: "Regionspot 1: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-3 > div:nth-of-type(2)::after {
  content: "Regionspot 2: Small";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-3 > div:nth-of-type(3)::after {
  content: "Regionspot 3: Medium";
}
html.mode-edit .ds-regionspots.ds-regionspots--variant-4 > div:nth-of-type(1)::after {
  content: "Regionspot 1: Mega";
}
