.ds-regionspots--variant-1 .ds-regionspot--mega {
  grid-column: 1 / span 2;
}
@media screen and (min-width: 1024px) {
  .ds-regionspots--variant-1 .ds-regionspot--mega {
    grid-column: 1 / span 4;
  }
}
.ds-regionspots--variant-1 .ds-regionspot--medium {
  grid-column: 1 / span 2;
}
.ds-regionspots--variant-1 .ds-regionspot--medium.ds-regionspot--5 {
  grid-row: 5;
}
@media screen and (min-width: 1024px) {
  .ds-regionspots--variant-1 .ds-regionspot--medium {
    grid-column: 3 / span 2;
  }
  .ds-regionspots--variant-1 .ds-regionspot--medium.ds-regionspot--5 {
    grid-column: 1 / span 2;
    grid-row: 3;
  }
}
.ds-regionspots--variant-2 .ds-regionspot--medium {
  grid-column: 1 / span 2;
}
.ds-regionspots--variant-2 .ds-regionspot--medium.ds-regionspot--4 {
  grid-row: 4;
}
@media screen and (min-width: 1024px) {
  .ds-regionspots--variant-2 .ds-regionspot--medium {
    grid-column: 3 / span 2;
  }
  .ds-regionspots--variant-2 .ds-regionspot--medium.ds-regionspot--4 {
    grid-column: 1 / span 2;
    grid-row: 2;
  }
}
.ds-regionspots--variant-3 .ds-regionspot--medium {
  grid-column: 1 / span 2;
}
@media screen and (min-width: 1024px) {
  .ds-regionspots--variant-3 .ds-regionspot--medium {
    grid-column: 3 / span 2;
  }
}
.ds-regionspots--variant-4 .ds-regionspot--mega {
  grid-column: 1 / span 2;
}
@media screen and (min-width: 1024px) {
  .ds-regionspots--variant-4 .ds-regionspot--mega {
    grid-column: 1 / span 4;
  }
}
