@import (reference) "./_imports";

.mediahub-download-app {
  .mediahub-container();
  padding: 2rem 4rem;
  margin: 4rem auto 3rem;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "description" "image" "buttons";
  position: relative;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 1.5rem;
    right: 1.5rem;
    top: 0;
    bottom: 0;
    background-color: @mediahub-color-black-600;
    border-radius: 1rem;
  }

  &:hover::after {
    background-color: @mediahub-color-black-700;
  }

  @media @lg {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "description image buttons";
    margin: 6rem auto 4rem;
  }

  @media @xxl {
    margin: 8rem auto 6rem;
    padding: 2rem 6rem;
  }
}

.mediahub-download-app__description {
  grid-area: description;
}

.mediahub-download-app__title {
  .mediahub-header-font(2.4rem, 0.5rem);
  text-align: center;

  @media @lg {
    text-align: left;
  }
}

.mediahub-download-app__text {
  .mediahub-base-font-light-gray(1.6rem);
  text-align: center;
  padding-bottom: 2rem;

  @media @lg {
    text-align: left;
    padding-bottom: 0;
  }
}


.mediahub-download-app__image {
  grid-area: image;

  .mediahub-download-app__image-prefix {
    .mediahub-base-font-light-gray(1.6rem);
    text-align: center;
    padding-bottom: 1rem;
  }

  img {
    display: block;
    height: 5.7rem;
    margin: 0 auto 2rem;

    @media @lg {
      margin-bottom: 0;
    }
  }
}

.mediahub-download-app__buttons {
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  @media @lg {
    justify-content: flex-end;
  }
}

.mediahub-download-app__cta {
  .mediahub-cta();

  &--download {
    .mediahub-cta-primary();
    width: auto;
  }
}
