﻿@import "../_imports";

.app-compliance-bar-casino {
  position: fixed;
  inset: 0;
  bottom: unset;
  height: 3.2rem;
  background: white;
}

.app-compliance-bar-casino__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.app-compliance-bar-casino__counter {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: 0.6rem;

  a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
    gap: 0.4rem;
    font-family: 'Raleway', Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;
    color: @ds-gray-875;
    text-decoration: none;
  }

  .app-compliance-bar-casino__counter-clock {
    transition: transform 0.3s ease;

    &--hidden {
      transform: translateY(-3.2rem);
    }
  }

  img {
    width: 1.8rem;
    height: 1.8rem;
  }
}

.app-compliance-bar-casino__compliance-link {
  height: 100%;

  a {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
  }

  img {
    height: 2rem;
    width: auto;
  }
}

.app-compliance-bar-casino__balance {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 1.6rem;
  font-family: 'Raleway', Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  color: @ds-gray-875;
  transition: transform 0.3s ease;

  &--hidden {
    transform: translateY(-3.2rem);
  }
}