/* ===========================================
   component.tournament-types
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
.poker-tournament-types {
  background: #1d1c21;
  color: #ffffff;
}
.poker-tournament-types__title {
  max-width: 100%;
  text-align: center;
}
.poker-tournament-types__items {
  display: flex;
  flex-flow: row wrap;
}
.poker-tournament-types__item {
  flex: 0 0 25%;
  max-width: 50%;
}
@media screen and (max-width: 767px) {
  .poker-tournament-types__item {
    flex: 0 0 50%;
    flex-basis: calc(100%/2 - 2rem / 2);
  }
  .poker-tournament-types__item:nth-child(even) {
    margin-left: 2rem;
  }
  .poker-tournament-types__item:nth-child(1n + 3) {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 767px) {
  .poker-tournament-types.has-items-per-row--4 .poker-tournament-types__item {
    flex-basis: calc(100%/4 - (2rem * 3) / 4);
  }
  .poker-tournament-types.has-items-per-row--4 .poker-tournament-types__item:not(:nth-child(4n + 1)) {
    margin-left: 2rem;
  }
  .poker-tournament-types.has-items-per-row--4 .poker-tournament-types__item:nth-child(1n + 5) {
    margin-top: 2rem;
  }
}
.poker-tournament-types.has-items-per-row--3 .poker-tournament-types__item {
  flex-basis: 33.33%;
}
@media screen and (min-width: 767px) {
  .poker-tournament-types.has-items-per-row--3 .poker-tournament-types__item {
    flex-basis: calc(100%/3 - (2rem * 3) / 4);
  }
  .poker-tournament-types.has-items-per-row--3 .poker-tournament-types__item:not(:nth-child(3n + 1)) {
    margin-left: 2rem;
  }
  .poker-tournament-types.has-items-per-row--3 .poker-tournament-types__item:nth-child(1n + 4) {
    margin-top: 2rem;
  }
}
.poker-tournament-types__link-area {
  display: block;
}
.poker-tournament-types__link-area:hover {
  text-decoration: none;
}
.poker-tournament-types__image {
  display: block;
  flex: none;
  width: 100%;
  height: calc( 18.75vw  -  3rem );
  object-fit: cover;
  object-position: 50% 50%;
  transition: .25s ease opacity;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .poker-tournament-types__image {
    height: auto !important;
  }
}
@supports (-ms-ime-align:auto) {
  .poker-tournament-types__image {
    height: auto !important;
  }
}
@media screen and (max-width: 767px) {
  .poker-tournament-types__image {
    height: calc( 37.5vw  - 4rem);
  }
}
@media screen and (min-width: 1024px) {
  .poker-tournament-types__image {
    height: 16.5rem;
  }
}
@media screen and (min-width: 767px) {
  .poker-tournament-types.has-items-per-row--3 .poker-tournament-types__image {
    height: calc( 25vw  -  3rem );
  }
}
@media screen and (min-width: 1024px) {
  .poker-tournament-types.has-items-per-row--3 .poker-tournament-types__image {
    height: 22.875rem;
  }
}
.poker-tournament-types__link-area:hover .poker-tournament-types__image {
  opacity: .5;
}
.poker-tournament-types__text {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 1rem;
}
@media screen and (min-width: 767px) {
  .poker-tournament-types__text {
    margin-top: 3rem;
  }
}
.poker-tournament-types__headline {
  transition: .25s ease color;
}
.poker-tournament-types__link-area:hover .poker-tournament-types__headline {
  color: #ffc328;
  color: var(--color-primary--light);
}
.poker-tournament-types__paragraph {
  margin: 0;
}
@media screen and (max-width: 767px) {
  .poker-tournament-types__paragraph {
    display: none;
  }
}
