@import (reference) url(../../AvalonComponents/Styles/_imports);

span[class^="playingcard-"] {
  width: 1.9em;
  height: 1.2em;
  font-size: inherit;
  line-height: inherit;
  display: inline-block;
  background-size: 1.8em 1em;
  background-color: @ds-white;
  border: 0.1rem solid @ds-gray-175;
  border-radius: 0.2em;
  margin-bottom: -0.2em;
  margin-left: 0.1em;
}

.createPlayingCardsClasses(@iterator:1) when (@iterator <= length(@playingCards)) {
  @name: extract(@playingCards, @iterator);
  @url: ~"url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#@{name}_use)";
  .playingcard-@{name} {
    background-image: @url;
  }
  .createPlayingCardsClasses(@iterator + 1);
}

@playingCards: H2, H3, H4, H5, H6, H7, H8, H9, H10, HA, HJ, HK, HQ, K2, K3, K4, K5, K6, K7, K8, K9, K10, KA, KJ, KK, KQ, R2, R3, R4, R5, R6, R7, R8, R9, R10, RA, RJ, RK, RQ, S2, S3, S4, S5, S6, S7, S8, S9, S10, SA, SJ, SK, SQ;
.createPlayingCardsClasses();
