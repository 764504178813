/* ===========================================
   component.tournament-types
   ========================================= */
// Components – class-based selectors, that define specific, designed UI components

@import "_imports.less";

.poker-tournament-types {
  background: @color-purple-100;
  color: @color-white;

  &__title {
    max-width: 100%;
    text-align: center;
  }

  &__items {
    display: flex;
    flex-flow: row wrap;
  }

  &__item {
    flex: 0 0 25%;
    max-width: 50%;

    @media screen and (max-width: @medium) {
      flex: 0 0 50%;
      flex-basis: calc(~'100%/2 - @{item-margin} / 2');

      &:nth-child(even) {
        margin-left: @item-margin;
      }

      &:nth-child(1n + 3) {
        margin-top: @item-margin;
      }
    }

    // Item layout styles, when 4 items per row
    .poker-tournament-types.has-items-per-row--4 & {
      @media screen and (min-width: @medium) {
        flex-basis: calc(~'100%/4 - (@{item-margin} * 3) / 4');

        &:not(:nth-child(4n + 1)) {
          margin-left: @item-margin;
        }

        &:nth-child(1n + 5) {
          margin-top: @item-margin;
        }
      }
    }

    // Item layout styles, when 3 items per row
    .poker-tournament-types.has-items-per-row--3 & {
      flex-basis: 33.33%;

      @media screen and (min-width: @medium) {
        flex-basis: calc(~'100%/3 - (@{item-margin} * 3) / 4');

        &:not(:nth-child(3n + 1)) {
          margin-left: @item-margin;
        }

        &:nth-child(1n + 4) {
          margin-top: @item-margin;
        }
      }
    }

  }

  &__link-area {
    display: block;

    &:hover {
      text-decoration: none;
    }
  }

  &__image {
    @format: 0.75; //width: 1, height: 0.75
    @widthItems4: 100 / 4;
    @widthItems3: 100 / 3;
    @widthItems2: 100 / 2;

    display: block;
    flex: none;
    width: 100%;

    // Height is calculated, so it keeps approx. the same image proportions.
    // This is a bit of a challenge, due to width is specified in % and height in vw.
    // Calculated, like so:
    // Height = width * @format - 'ca. spacing to viewport edges'
    height: ~'calc('@widthItems4 * @format * 1vw ~' - '@container-padding ~')';

    // IE11 Specific style. Because object-fit is not supported in IE.
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: auto !important;
    }

    // Edge Specific style. Because object-fit is not supported untill Edge 16.
    @supports (-ms-ime-align:auto) {
      height: auto !important;
    }

    object-fit: cover;
    object-position: 50% 50%;

    transition: .25s ease opacity;

    @media screen and (max-width: @medium) {
      height: ~'calc('@widthItems2 * @format * 1vw ~' - 4rem)';
    }

    @media screen and (min-width: @large) {
      // set static height when grid reaches max-width (and width of item becomes static)
      height: 220 * @format * 0.1rem;
    }

    .poker-tournament-types.has-items-per-row--3 & {
      @media screen and (min-width: @medium) {
        height: ~'calc('@widthItems3 * @format * 1vw ~' - '@container-padding ~')';
      }

      @media screen and (min-width: @large) {
        // set static height when grid reaches max-width (and width of item becomes static)
        height: 305 * @format * 0.1rem;
      }
    }

    .poker-tournament-types__link-area:hover & {
      opacity: .5;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: @spacing-unit--sm;

    @media screen and (min-width: @medium) {
      margin-top: @spacing-unit--lg;
    }
  }

  &__headline {
    transition: .25s ease color;
    .poker-tournament-types__link-area:hover & {
      color: @color-primary--light;
      color: var(--color-primary--light);
    }
  }

  &__paragraph {
    margin: 0;

    @media screen and (max-width: @medium) {
      display: none;
    }
  }
}
