﻿@import "../../../../../../../DanskeSpil/Framework/Design/Styles/Mixins/DanskeSpilMixins.less"; 

.rte-styling() {
  p {
    margin: 0 0 0.8em;
  }

  h1, h2, h3, h4 {
    margin: 0;
  }

  h1 {
    .cm-font-size(2.0);
    margin-bottom: 1.0em;
  }

  h2 {
    .cm-font-size(1.8);
    margin-bottom: .5em;
  }

  h3 {
    .cm-font-size(1.5);
  }

  h4 {
    .cm-font-size(1.5);
    font-style: italic;
  }

  a {
    color: #333;
    text-decoration: underline;

    .cm-brand-buttons();

  }
}