.ds-rich-text h1,
.ds-rich-text h2,
.ds-rich-text h3,
.ds-rich-text h4,
.ds-rich-text h5,
.ds-rich-text h6 {
  font-family: 'AvenirNext', sans-serif;
  margin: 0 0 1rem;
  font-style: italic;
}
.ds-rich-text h1 {
  font-size: 5.2rem;
  font-weight: 700;
  line-height: 5rem;
}
.ds-rich-text h2 {
  font-size: 4.2rem;
  font-weight: 700;
  line-height: 4rem;
}
.ds-rich-text h3 {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3rem;
}
.ds-rich-text p {
  margin: 0;
}
.ds-rich-text a {
  color: currentColor;
  text-decoration: underline;
}
.ds-rich-text a:hover {
  text-decoration: none;
}
.ds-rich-text ul,
.ds-rich-text ol {
  margin-left: -1ch;
}
.ds-rich-text ul li,
.ds-rich-text ol li {
  margin-bottom: 0.5em;
}
.ds-rich-text ul {
  list-style: disc;
  margin-left: 3ch;
}
