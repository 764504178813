.campaign-page .campaigns-header:before,
.campaign-page .campaigns-footer:before,
.campaign-page .campaigns-header:after,
.campaign-page .campaigns-footer:after {
  content: "";
  display: table;
}
.campaign-page .campaigns-header:after,
.campaign-page .campaigns-footer:after {
  clear: both;
}
.campaign-page .campaigns-header .size-full,
.campaign-page .campaigns-footer .size-full {
  padding: 0 12px;
}
.campaign-page .main-column:before,
.campaign-page .main-column:after {
  content: "";
  display: table;
}
.campaign-page .main-column:after {
  clear: both;
}
.campaign-page-container {
  padding: 20px 12px 10px;
}
.iframe-page .campaign-page-container {
  padding: 0;
}
.campaign-page-container .inner-wrapper {
  padding: 30px 18px 10px;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -moz-box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.5);
}
.color-bingo-lime .campaign-page-container .inner-wrapper {
  background-color: #a0d323;
}
.color-blitz-bordeaux .campaign-page-container .inner-wrapper {
  background-color: #6b0000;
}
.color-blitz-white .campaign-page-container .inner-wrapper {
  background-color: #ffffff;
}
.color-casino-gold .campaign-page-container .inner-wrapper {
  background-color: #a78952;
}
.color-ds-green .campaign-page-container .inner-wrapper {
  background-color: #004b32;
}
.color-ds-yellow .campaign-page-container .inner-wrapper {
  background-color: #feb700;
}
.color-dantoto-orange .campaign-page-container .inner-wrapper {
  background-color: #dc5f11;
}
.color-eurojackpot-gold .campaign-page-container .inner-wrapper {
  background-color: #e8a332;
}
.color-keno-lightblue .campaign-page-container .inner-wrapper {
  background-color: #00a5eb;
}
.color-lotto-red .campaign-page-container .inner-wrapper {
  background-color: #c50005;
}
.color-oddset-darkblue .campaign-page-container .inner-wrapper {
  background-color: #003e99;
}
.color-oddset-grey .campaign-page-container .inner-wrapper {
  background-color: #2b2b2b;
}
.color-oddset-orange .campaign-page-container .inner-wrapper {
  background-color: #dc5f11;
}
.color-onsdagslotto-blue .campaign-page-container .inner-wrapper {
  background-color: #0072ba;
}
.color-poker-black .campaign-page-container .inner-wrapper {
  background-color: #000000;
}
.color-quick-violet .campaign-page-container .inner-wrapper {
  background-color: #7f4488;
}
.color-tips-green .campaign-page-container .inner-wrapper {
  background-color: #009a60;
}
.color-cego-greyblue .campaign-page-container .inner-wrapper {
  background-color: #7d96b4;
}
.color-spillehjoernet-greyblue .campaign-page-container .inner-wrapper {
  background-color: #7d96b4;
}
.campaign-page-container .inner-wrapper:before,
.campaign-page-container .inner-wrapper:after {
  content: "";
  display: table;
}
.campaign-page-container .inner-wrapper:after {
  clear: both;
}
.campaign-page-container .inner-wrapper.hide-border {
  padding: 0;
}
.campaign-page-container .inner-wrapper .size-full {
  padding: 0 !important;
}
.iframe-page .campaign-page-container .inner-wrapper {
  padding: 0;
  box-shadow: none;
}
.campaign-page-container .image img {
  width: 100%;
  height: auto;
  border-radius: 3px 3px 0 0;
  margin-bottom: -3px;
}
.campaign-page-container .content {
  background: #fff;
  padding: 20px 30px;
  font-size: 1.4rem;
}
.campaign-page-container .content .teaser {
  font-size: 1.6rem;
  line-height: 1.3;
  font-weight: bold;
}
.campaign-page-container .content .content-title {
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 1.5rem;
  margin-top: 30px;
  font-style: normal;
}
.campaign-page-container .content .text {
  padding-left: 17px;
}
.campaign-page-container .content ul {
  padding-left: 29px;
}
.campaign-page-container .content ul li {
  line-height: 18px;
  list-style-type: square;
}
.campaign-page-container .content ol {
  padding-left: 29px;
}
.campaign-page-container .content ol li {
  line-height: 18px;
}
.campaign-page-container .content img {
  max-width: 100%;
  height: auto;
}
.campaign-page-container .content .heading {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 3rem;
  line-height: 3.5rem;
}
.campaign-page-container .content p {
  margin: 0;
}
.campaign-page-container .content h1,
.campaign-page-container .content h2,
.campaign-page-container .content h3,
.campaign-page-container .content h4 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: normal;
}
.campaign-page-container .content h1 {
  line-height: 2.0;
}
.campaign-page-container .content h2 {
  line-height: 1.0;
}
.campaign-page-container .content h3 {
  line-height: 0.8;
}
.campaign-page-container .content h4 {
  line-height: 0.6;
}
.campaign-page-container .content p {
  margin: 0 0 0.8em;
}
.campaign-page-container .content h1,
.campaign-page-container .content h2,
.campaign-page-container .content h3,
.campaign-page-container .content h4 {
  margin: 0;
}
.campaign-page-container .content h1 {
  font-size: 2rem;
  margin-bottom: 1.0em;
}
.campaign-page-container .content h2 {
  font-size: 1.8rem;
  margin-bottom: .5em;
}
.campaign-page-container .content h3 {
  font-size: 1.5rem;
}
.campaign-page-container .content h4 {
  font-size: 1.5rem;
  font-style: italic;
}
.campaign-page-container .content a {
  color: #333;
  text-decoration: underline;
  /*  .cm-tips-button() {
    .cm-brand-buttons-style-three(@topColor: rgba(224,118,51,1), @bottomColor: rgba(188,82,15,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.75), inset 0 0 0 1px rgba(255,255,255,0.20);
    text-shadow: 0 1px 1px rgba(156,60,0,0.50);
  }
  .cm-tips-button-large() {
    .cm-brand-buttons-style-three(@height: 60px, @minWidth: 220px, @fontSize: 2, @topColor: rgba(224,118,51,1), @bottomColor: rgba(188,82,15,1));
    box-shadow: 0 1px 2px rgba(0,0,0,0.75), inset 0 0 0 1px rgba(255,255,255,0.20);
    text-shadow: 0 1px 1px rgba(156,60,0,0.50);
  }*/
}
.campaign-page-container .content a.bingo-button {
  display: table-cell;
  height: 30px;
  min-width: 100px;
  padding: 0 25px 5px;
  background-color: #80a91c;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #607f0b, inset 0 1px 0 0 rgba(160, 211, 35, 0.8);
  text-shadow: 0 1px 0 #607f15;
}
.campaign-page-container .content a.bingo-button:hover {
  background-color: #8fb82b;
}
.campaign-page-container .content a.bingo-button-large {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #80a91c;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #607f0b, inset 0 1px 0 0 rgba(160, 211, 35, 0.8);
  text-shadow: 0 1px 0 #607f15;
}
.campaign-page-container .content a.bingo-button-large:hover {
  background-color: #8fb82b;
}
.campaign-page-container .content a.casino-button {
  display: table-cell;
  height: 30px;
  min-width: 100px;
  padding: 0 25px 5px;
  background-color: #652044;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #430d29, inset 0 1px 0 0 rgba(151, 48, 102, 0.8);
  text-shadow: 0 1px 0 #7c643b;
}
.campaign-page-container .content a.casino-button:hover {
  background-color: #742f53;
}
.campaign-page-container .content a.casino-button-large {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #652044;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #430d29, inset 0 1px 0 0 rgba(151, 48, 102, 0.8);
  text-shadow: 0 1px 0 #7c643b;
}
.campaign-page-container .content a.casino-button-large:hover {
  background-color: #742f53;
}
.campaign-page-container .content a.dantoto-button {
  display: table-cell;
  height: 30px;
  min-width: 100px;
  padding: 0 25px 5px;
  background-color: #47ab66;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #1f7a44, inset 0 1px 0 0 rgba(92, 200, 125, 0.8);
  text-shadow: 0 1px 0 #1f7a44;
}
.campaign-page-container .content a.dantoto-button:hover {
  background-color: #56ba75;
}
.campaign-page-container .content a.dantoto-button-large {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #47ab66;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #1f7a44, inset 0 1px 0 0 rgba(92, 200, 125, 0.8);
  text-shadow: 0 1px 0 #1f7a44;
}
.campaign-page-container .content a.dantoto-button-large:hover {
  background-color: #56ba75;
}
.campaign-page-container .content a.quick-button {
  display: table-cell;
  height: 30px;
  min-width: 100px;
  padding: 0 25px 5px;
  background-color: #7f4488;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #5e2767, inset 0 1px 0 0 rgba(166, 124, 172, 0.8);
  text-shadow: 0 1px 0 #7f4488;
}
.campaign-page-container .content a.quick-button:hover {
  background-color: #8e5397;
}
.campaign-page-container .content a.quick-button-large {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #7f4488;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #5e2767, inset 0 1px 0 0 rgba(166, 124, 172, 0.8);
  text-shadow: 0 1px 0 #7f4488;
}
.campaign-page-container .content a.quick-button-large:hover {
  background-color: #8e5397;
}
.campaign-page-container .content a.cross-sales-button {
  display: table-cell;
  height: 30px;
  min-width: 100px;
  padding: 0 25px 5px;
  background-color: #feb700;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  color: #004b32;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #5e2767, inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.5);
}
.campaign-page-container .content a.cross-sales-button:hover {
  background-color: #ffc60f;
}
.campaign-page-container .content a.cross-sales-button-large {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #feb700;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  color: #004b32;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #5e2767, inset 0 1px 0 0 rgba(255, 255, 255, 0.5);
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.5);
}
.campaign-page-container .content a.cross-sales-button-large:hover {
  background-color: #ffc60f;
}
.campaign-page-container .content a.eurojackpot-button {
  display: table-cell;
  height: 34px;
  min-width: 100px;
  padding: 0 12px 5px;
  background: #000000;
  background: #000000 radial-gradient(circle at 28% top, #323232, #000000) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 1.4rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  padding-bottom: 0px;
  font-family: 'Anton', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fec115;
}
.campaign-page-container .content a.eurojackpot-button:hover {
  background: #0f0f0f radial-gradient(circle at 28% top, #414141, #0f0f0f) no-repeat;
}
.campaign-page-container .content a.eurojackpot-button-large {
  display: table-cell;
  height: 69px;
  min-width: 200px;
  padding: 0 12px 5px;
  background: #000000;
  background: #000000 radial-gradient(circle at 28% top, #323232, #000000) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  padding-bottom: 0px;
  font-family: 'Anton', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fec115;
}
.campaign-page-container .content a.eurojackpot-button-large:hover {
  background: #0f0f0f radial-gradient(circle at 28% top, #414141, #0f0f0f) no-repeat;
}
.campaign-page-container .content a.lotto-button {
  display: table-cell;
  height: 34px;
  min-width: 100px;
  padding: 0 12px 5px;
  background: #feb700;
  background: #feb700 radial-gradient(circle at 28% top, #fecd40, #feb700) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 1.3rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 0 #dfa100;
  text-shadow: 0 1px 1px rgba(223, 161, 0, 0.8);
}
.campaign-page-container .content a.lotto-button:hover {
  background: #ffc60f radial-gradient(circle at 28% top, #ffdc4f, #ffc60f) no-repeat;
}
.campaign-page-container .content a.lotto-button-large {
  display: table-cell;
  height: 69px;
  min-width: 200px;
  padding: 0 12px 5px;
  background: #feb700;
  background: #feb700 radial-gradient(circle at 28% top, #fecd40, #feb700) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 1.7rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 0 #dfa100;
  text-shadow: 0 1px 1px rgba(223, 161, 0, 0.8);
}
.campaign-page-container .content a.lotto-button-large:hover {
  background: #ffc60f radial-gradient(circle at 28% top, #ffdc4f, #ffc60f) no-repeat;
}
.campaign-page-container .content a.danskespil-button {
  display: table-cell;
  height: 36px;
  min-width: 100px;
  padding: 0 12px 5px;
  background: #ffc425;
  background: linear-gradient(to top, #feb700, #ffc425);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 1.4rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.campaign-page-container .content a.danskespil-button:hover {
  background: linear-gradient(to top, #ffc60f, #ffd334);
}
.campaign-page-container .content a.danskespil-button-large {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #ffc425;
  background: linear-gradient(to top, #feb700, #ffc425);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.campaign-page-container .content a.danskespil-button-large:hover {
  background: linear-gradient(to top, #ffc60f, #ffd334);
}
.campaign-page-container .content a.oddset-button {
  display: table-cell;
  height: 36px;
  min-width: 100px;
  padding: 0 12px 5px;
  background: #003e99;
  background: linear-gradient(to top, #002f71, #003e99);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.campaign-page-container .content a.oddset-button:hover {
  background: linear-gradient(to top, #0f3e80, #0f4da8);
}
.campaign-page-container .content a.oddset-button-large {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #003e99;
  background: linear-gradient(to top, #002f71, #003e99);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.campaign-page-container .content a.oddset-button-large:hover {
  background: linear-gradient(to top, #0f3e80, #0f4da8);
}
.campaign-page-container .content a.oddset-button-orange {
  display: table-cell;
  height: 36px;
  min-width: 100px;
  padding: 0 12px 5px;
  background: #dc5f11;
  background: linear-gradient(to top, #ae4a0c, #dc5f11);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-shadow: 0 1px 1px rgba(156, 60, 0, 0.5);
}
.campaign-page-container .content a.oddset-button-orange:hover {
  background: linear-gradient(to top, #bd591b, #eb6e20);
}
.campaign-page-container .content a.oddset-button-large-orange {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #dc5f11;
  background: linear-gradient(to top, #ae4a0c, #dc5f11);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-shadow: 0 1px 1px rgba(156, 60, 0, 0.5);
}
.campaign-page-container .content a.oddset-button-large-orange:hover {
  background: linear-gradient(to top, #bd591b, #eb6e20);
}
.campaign-page-container .content a.poker-button {
  display: table-cell;
  height: 36px;
  min-width: 100px;
  padding: 0 12px 5px;
  background: #feb804;
  background: linear-gradient(to top, #d9a00e, #feb804);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #000000;
}
.campaign-page-container .content a.poker-button:hover {
  background: linear-gradient(to top, #e8af1d, #ffc713);
}
.campaign-page-container .content a.poker-button-large {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #feb804;
  background: linear-gradient(to top, #d9a00e, #feb804);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #000000;
}
.campaign-page-container .content a.poker-button-large:hover {
  background: linear-gradient(to top, #e8af1d, #ffc713);
}
.campaign-page-container .content a.tips-button {
  display: table-cell;
  height: 34px;
  min-width: 100px;
  padding: 0 12px 5px;
  background: #00985f;
  background: #00985f radial-gradient(circle at 28% top, #56ba75, #00985f) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 1.3rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.campaign-page-container .content a.tips-button:hover {
  background: #0fa76e radial-gradient(circle at 28% top, #65c984, #0fa76e) no-repeat;
}
.campaign-page-container .content a.tips-button-large {
  display: table-cell;
  height: 69px;
  min-width: 200px;
  padding: 0 12px 5px;
  background: #00985f;
  background: #00985f radial-gradient(circle at 28% top, #56ba75, #00985f) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 1.7rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.campaign-page-container .content a.tips-button-large:hover {
  background: #0fa76e radial-gradient(circle at 28% top, #65c984, #0fa76e) no-repeat;
}
.campaign-page-container .content a.spillehjoernet-button {
  display: table-cell;
  height: 36px;
  min-width: 100px;
  padding: 0 12px 5px;
  background: #d5549d;
  background: linear-gradient(to top, #702765, #d5549d);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 1.5rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
}
.campaign-page-container .content a.spillehjoernet-button:hover {
  background: linear-gradient(to top, #7f3674, #e463ac);
}
.campaign-page-container .content a.spillehjoernet-button-large {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #d5549d;
  background: linear-gradient(to top, #702765, #d5549d);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.75);
}
.campaign-page-container .content a.spillehjoernet-button-large:hover {
  background: linear-gradient(to top, #7f3674, #e463ac);
}
.campaign-page-container .content .action-section {
  text-align: center;
}
.campaign-page-container .content.has-background {
  color: #fff;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}
.campaign-page-container .content.has-background a {
  color: #fff;
}
.campaign-page-container .link-block > .link-block-button-container {
  display: inline-block;
}
.color-bingo-lime .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #80a91c;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #607f0b, inset 0 1px 0 0 rgba(160, 211, 35, 0.8);
  text-shadow: 0 1px 0 #607f15;
}
.color-bingo-lime .campaign-page-container .link-block a:hover {
  background-color: #8fb82b;
}
.color-blitz-bordeaux .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #ffc425;
  background: linear-gradient(to top, #feb700, #ffc425);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.color-blitz-bordeaux .campaign-page-container .link-block a:hover {
  background: linear-gradient(to top, #ffc60f, #ffd334);
}
.color-blitz-white .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #ffc425;
  background: linear-gradient(to top, #feb700, #ffc425);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.color-blitz-white .campaign-page-container .link-block a:hover {
  background: linear-gradient(to top, #ffc60f, #ffd334);
}
.color-casino-gold .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #652044;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #430d29, inset 0 1px 0 0 rgba(151, 48, 102, 0.8);
  text-shadow: 0 1px 0 #7c643b;
}
.color-casino-gold .campaign-page-container .link-block a:hover {
  background-color: #742f53;
}
.color-ds-green .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #ffc425;
  background: linear-gradient(to top, #feb700, #ffc425);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.color-ds-green .campaign-page-container .link-block a:hover {
  background: linear-gradient(to top, #ffc60f, #ffd334);
}
.color-ds-yellow .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #ffc425;
  background: linear-gradient(to top, #feb700, #ffc425);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}
.color-ds-yellow .campaign-page-container .link-block a:hover {
  background: linear-gradient(to top, #ffc60f, #ffd334);
}
.color-dantoto-orange .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #47ab66;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #1f7a44, inset 0 1px 0 0 rgba(92, 200, 125, 0.8);
  text-shadow: 0 1px 0 #1f7a44;
}
.color-dantoto-orange .campaign-page-container .link-block a:hover {
  background-color: #56ba75;
}
.color-eurojackpot-gold .campaign-page-container .link-block a {
  display: table-cell;
  height: 69px;
  min-width: 200px;
  padding: 0 12px 5px;
  background: #000000;
  background: #000000 radial-gradient(circle at 28% top, #323232, #000000) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  padding-bottom: 0px;
  font-family: 'Anton', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fec115;
}
.color-eurojackpot-gold .campaign-page-container .link-block a:hover {
  background: #0f0f0f radial-gradient(circle at 28% top, #414141, #0f0f0f) no-repeat;
}
.color-keno-lightblue .campaign-page-container .link-block a {
  display: table-cell;
  height: 69px;
  min-width: 200px;
  padding: 0 12px 5px;
  background: #feb700;
  background: #feb700 radial-gradient(circle at 28% top, #fecd40, #feb700) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 1.7rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 0 #dfa100;
  text-shadow: 0 1px 1px rgba(223, 161, 0, 0.8);
}
.color-keno-lightblue .campaign-page-container .link-block a:hover {
  background: #ffc60f radial-gradient(circle at 28% top, #ffdc4f, #ffc60f) no-repeat;
}
.color-live-casino-gold .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #652044;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #430d29, inset 0 1px 0 0 rgba(151, 48, 102, 0.8);
  text-shadow: 0 1px 0 #7c643b;
}
.color-live-casino-gold .campaign-page-container .link-block a:hover {
  background-color: #742f53;
}
.color-lotto-red .campaign-page-container .link-block a {
  display: table-cell;
  height: 69px;
  min-width: 200px;
  padding: 0 12px 5px;
  background: #feb700;
  background: #feb700 radial-gradient(circle at 28% top, #fecd40, #feb700) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 1.7rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 0 #dfa100;
  text-shadow: 0 1px 1px rgba(223, 161, 0, 0.8);
}
.color-lotto-red .campaign-page-container .link-block a:hover {
  background: #ffc60f radial-gradient(circle at 28% top, #ffdc4f, #ffc60f) no-repeat;
}
.color-oddset-darkblue .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #dc5f11;
  background: linear-gradient(to top, #ae4a0c, #dc5f11);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-shadow: 0 1px 1px rgba(156, 60, 0, 0.5);
}
.color-oddset-darkblue .campaign-page-container .link-block a:hover {
  background: linear-gradient(to top, #bd591b, #eb6e20);
}
.color-oddset-grey .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #003e99;
  background: linear-gradient(to top, #002f71, #003e99);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.color-oddset-grey .campaign-page-container .link-block a:hover {
  background: linear-gradient(to top, #0f3e80, #0f4da8);
}
.color-oddset-orange .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #003e99;
  background: linear-gradient(to top, #002f71, #003e99);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.75), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.color-oddset-orange .campaign-page-container .link-block a:hover {
  background: linear-gradient(to top, #0f3e80, #0f4da8);
}
.color-onsdagslotto-blue .campaign-page-container .link-block a {
  display: table-cell;
  height: 69px;
  min-width: 200px;
  padding: 0 12px 5px;
  background: #feb700;
  background: #feb700 radial-gradient(circle at 28% top, #fecd40, #feb700) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 1.7rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 0 #dfa100;
  text-shadow: 0 1px 1px rgba(223, 161, 0, 0.8);
}
.color-onsdagslotto-blue .campaign-page-container .link-block a:hover {
  background: #ffc60f radial-gradient(circle at 28% top, #ffdc4f, #ffc60f) no-repeat;
}
.color-poker-black .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 12px 5px;
  background: #feb804;
  background: linear-gradient(to top, #d9a00e, #feb804);
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-weight: normal;
  font-size: 2rem;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  color: #000000;
}
.color-poker-black .campaign-page-container .link-block a:hover {
  background: linear-gradient(to top, #e8af1d, #ffc713);
}
.color-quick-violet .campaign-page-container .link-block a {
  display: table-cell;
  height: 60px;
  min-width: 220px;
  padding: 0 25px 5px;
  background-color: #7f4488;
  border-radius: 3px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #ffffff;
  line-height: 1.1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 4px 2px 0 rgba(0, 0, 0, 0.4), 0 0 0 1px #5e2767, inset 0 1px 0 0 rgba(166, 124, 172, 0.8);
  text-shadow: 0 1px 0 #7f4488;
}
.color-quick-violet .campaign-page-container .link-block a:hover {
  background-color: #8e5397;
}
.color-tips-green .campaign-page-container .link-block a {
  display: table-cell;
  height: 69px;
  min-width: 200px;
  padding: 0 12px 5px;
  background: #00985f;
  background: #00985f radial-gradient(circle at 28% top, #56ba75, #00985f) no-repeat;
  border-radius: 4px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: italic;
  font-size: 1.7rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  vertical-align: middle;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.color-tips-green .campaign-page-container .link-block a:hover {
  background: #0fa76e radial-gradient(circle at 28% top, #65c984, #0fa76e) no-repeat;
}
