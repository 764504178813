.ds-parallax-quote {
  background-color: var(--parallaxBackgroundColor, #004b32);
  background-repeat: no-repeat;
  background-image: var(--parallaxMobileQuote);
  background-position: center calc(50lvh - var(--parallaxMobileQuoteHeight, 9rem) / 2 + 9rem), center center;
  background-size: auto, cover;
}
.ds-parallax-quote--has-bg-img {
  background-image: var(--parallaxMobileQuote), var(--parallaxBackgroundImage);
}
body:not(.is-mobile-detected.ios-device) .ds-parallax-quote {
  background-attachment: fixed;
  animation-name: parallaxPositionBackgroundImage;
}
@supports (animation-timeline: scroll()) {
  body:not(.is-mobile-detected.ios-device) .ds-parallax-quote {
    animation-timeline: scroll();
    /* stylelint-disable-line property-no-unknown */
  }
}
body.is-mobile-detected.ios-device .ds-parallax-quote {
  background-position: center;
}
.ds-parallax-quote > div {
  min-height: calc(var(--parallaxMobileQuoteHeight, 9rem) + 12rem);
}
@media screen and (min-width: 768px) {
  .ds-parallax-quote > div {
    min-height: calc(var(--parallaxMobileQuoteHeight, 9rem) + 20rem);
  }
}
@media screen and (min-width: 1024px) {
  .ds-parallax-quote > div {
    min-height: calc(var(--parallaxDesktopQuoteHeight, 9rem) + 30rem);
  }
}
@media screen and (min-width: 1024px) {
  .ds-parallax-quote {
    background-position: center calc(50lvh - var(--parallaxDesktopQuoteHeight, 9rem) / 2 + 9rem), center center;
    background-image: var(--parallaxDesktopQuote);
  }
  .ds-parallax-quote--has-bg-img {
    background-image: var(--parallaxDesktopQuote), var(--parallaxBackgroundImage);
  }
}
.ds-dice-slice.slicebuttom + .ds-hero .ds-hero__inner {
  padding-top: min(15vw, 188px);
}
.ds-parallax__bottom {
  clip-path: polygon(0% var(--parallaxBottomStickyTop, 0), 100% var(--parallaxBottomStickyTop, 0), 100% 100%, 0% 100%);
}
@keyframes parallaxPositionBackgroundImage {
  0% {
    background-position: center 60%, center calc(50% + 25lvh);
  }
  100% {
    background-position: center 30%, center calc(50% - 10lvh);
  }
}
