@import (reference) "./_imports";

.mediahub-episodes-highlight {
  .mediahub-container();
  padding: 0;
}

.mediahub-episodes-highlight__title {
  .mediahub-header-font(1.6rem, 1rem);
  padding: 0 1.5rem;

  @media @lg {
    .mediahub-header-font(2.4rem, 2rem);
  }
}

.mediahub-episodes-highlight__contents {
  white-space: nowrap;
  overflow: hidden;
  height: 19rem;

  .mediahub-episodes-highlight--selected & {
    height: 35.9rem;

    @media @lg {
      height: auto;
    }
  }

  @media @lg {
    overflow: hidden;
    height: auto;
    white-space: normal;
  }
}

.mediahub-episodes-highlight__contents-inner {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 1rem 5rem 1.5rem;

  @media @lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
    display: grid;
    grid-template-columns: repeat(4, 23.5rem);
    grid-template-rows: repeat(auto-fill, 35.9rem);
    gap: ~"calc((100% - 4 * 23.5rem) / 3)";
    padding: 0 1.5rem;
  }

  @media @xxl {
    grid-template-columns: repeat(5, 23.5rem);
    gap: ~"calc((100% - 5 * 23.5rem) / 4)";
  }

  .mediahub-episodes-highlight--selected & {
    @media @lg {
      grid-template-columns: repeat(3, 30.5rem);
      grid-template-rows: repeat(auto-fill, 42.4rem);
      gap: ~"calc((100% - 3 * 30.5rem) / 2)";
    }

    @media @xxl {
      grid-template-columns: repeat(3, 39rem);
      grid-template-rows: repeat(auto-fill, 50.6rem);
      gap: ~"calc((100% - 3 * 39rem) / 2)";

    }
  }
}

.mediahub-episodes-highlight__episode {
  white-space: normal;
  position: relative;
  display: inline-grid;
  margin-right: 1rem;
  grid-template-columns: 14.5rem;
  grid-template-rows: 14.5rem 1fr;
  row-gap: 0.5rem;
  grid-template-areas: "card" "title";

  &:hover {
    background-color: @mediahub-color-black-700;

    .mediahub-episodes-highlight__episode-card {
      .mediahub-card--hover();
    }
  }

  @media @sm {
    row-gap: 0.8rem;
  }

  @media @lg {
    height: 35.9rem;
    padding: 1.75rem 1.75rem 2rem;
    margin-right: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 21rem auto 1fr;
    grid-template-areas: "card card" "title title" "date duration";
    background-color: @mediahub-color-black-650;
    border-radius: 1rem;
    overflow: hidden;

    &:nth-child(1n + 5) {
      display: none;
    }
  }

  @media @xxl {
    &:nth-child(1n + 5) {
      display: inline-grid;
    }

    &:nth-child(1n + 6) {
      display: none;
    }
  }

  .mediahub-episodes-highlight--selected & {
    width: 25rem;
    height: 35.9rem;
    padding: 1.5rem 1.5rem 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 22.5rem auto 1fr;
    grid-template-areas: "card card" "title title" "date duration";
    background-color: @mediahub-color-black-650;
    border-radius: 1rem;
    overflow: hidden;

    @media @lg {
      width: auto;
      height: 42.4rem;
      padding: 2rem;
      grid-template-rows: 27.8rem auto 1fr;

      &:nth-child(1n + 4) {
        display: none;
      }
    }

    @media @xxl {
      height: 50.6rem;
      grid-template-rows: 36rem auto 1fr;
    }
  }
}

h3.mediahub-episodes-highlight__episode-title {
  grid-area: title;
  .mediahub-base-font(1.2rem);
  .mediahub-lines-of-text(2);
  padding: 0 0.2rem;

  .mediahub-episodes-highlight--selected & {
    .mediahub-header-font(1.6rem);
    padding: 0;
  }

  @media @lg {
    .mediahub-header-font(1.6rem);
    padding: 0;
  }
}

.mediahub-episodes-highlight__episode-text {
  display: none;
}

.mediahub-episodes-highlight__episode-date {
  grid-area: date;
  display: none;
  .mediahub-base-font-gray(1.4rem);
  align-items: flex-end;

  .mediahub-episodes-highlight--selected & {
    display: flex;
  }

  @media @lg {
    display: flex;
  }
}

.mediahub-episodes-highlight__episode-duration {
  grid-area: duration;
  display: none;
  .mediahub-base-font-gray(1.4rem);
  align-items: flex-end;
  justify-content: flex-end;

  .mediahub-episodes-highlight--selected & {
    display: flex;
  }

  @media @lg {
    display: flex;
  }
}

.mediahub-episodes-highlight__episode-cta {
  display: none;
}

.mediahub-episodes-highlight__episode-card {
  .mediahub-card();
  grid-area: card;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;

  .mediahub-episodes-highlight--selected & {
    width: 22rem;
    height: 22rem;

    @media @lg {
      width: 26.75rem;
      height: 26.75rem;
    }

    @media @xxl {
      width: 35.25rem;
      height: 35.25rem;
    }
  }
}

.mediahub-episodes-highlight__episode-card-logo {
  .mediahub-card-logo();
}

.mediahub-episodes-highlight__episode-card-bg {
  .mediahub-card-bg();

  .mediahub-episodes-highlight__episode--article & {
    .mediahub-card-type-article--sm();

    .mediahub-episodes-highlight--selected & {
      .mediahub-card-type-article--lg();
    }

    @media @lg {
      .mediahub-card-type-article--lg();
    }
  }

  .mediahub-episodes-highlight__episode--podcast & {
    .mediahub-card-type-podcast--sm();

    .mediahub-episodes-highlight--selected & {
      .mediahub-card-type-podcast--lg();
    }

    @media @lg {
      .mediahub-card-type-podcast--lg();
    }
  }

  .mediahub-episodes-highlight__episode--video & {
    .mediahub-card-type-video--sm();

    .mediahub-episodes-highlight--selected & {
      .mediahub-card-type-video--lg();
    }

    @media @lg {
      .mediahub-card-type-video--lg();
    }
  }
}
