body.region-poker {
  background-color: #12121A;
}
.top-navigation-theme-poker .menu-toggle-right {
  background-color: #12121A;
}
.top-navigation-theme-poker .brand-nav {
  background-color: #12121A;
}
.top-navigation-theme-poker .corporate-nav {
  background-color: #443E4C;
}
.top-navigation-theme-poker .corporate-nav__list-item:not(.js-has-subnav) .corporate-nav__list-link:hover {
  color: white !important;
}
.top-navigation-theme-poker .brand-nav .mobile-navigation-header {
  background-color: #12121A;
}
.top-navigation-theme-poker .brand-nav .mobile-navigation-footer-icon {
  background-color: #12121A;
}
@media all and (min-width: 950px) {
  .top-navigation-theme-poker .brand-nav__item:not(.js-has-subnav) .brand-nav__link:hover {
    color: white !important;
  }
}
.top-navigation-theme-poker .brand-nav__link:before {
  background-color: #12121A;
}
.top-navigation-theme-poker .mega-menu__campaign__cta-button {
  background-color: #12121A;
  color: white;
}
.top-navigation-theme-poker .mega-menu__campaign__cta-button:hover {
  background-color: #443E4C;
}
