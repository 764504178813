@import (reference) "./_imports";

.mediahub-series-content {
  .mediahub-container();
}

.mediahub-series-content__title {
  .mediahub-header-font(2rem, 2rem);

  @media @xl {
    .mediahub-header-font(3.2rem, 2rem);
  }
}

.mediahub-series-content__episode-id {
  position: absolute;
  z-index: 10000;
  top: -7rem;
  left: 0;
  width: 0;
  height: 0;

  @media @md {
    top: -15rem;
  }
}

.mediahub-series-content__episode {
  padding: 0 0 3rem;
  margin-bottom: 1rem;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-areas: "title title title" "text text text" "readmore readmore readmore" "participants participants participants" "date duration cta";

  @media @xl {
    padding-left: 15rem;
    min-height: 14.5rem;
  }

  &::after {
    pointer-events: none;
    content: "";
    position: absolute;
    z-index: -2;
    left: 0;
    right: 0;
    top: -2.5rem;
    border-top: 0.1rem solid @ds-black-700;
  }

  .mediahub-series-content__title + &::after {
    border-top: none;
  }

  &:hover::after {
    z-index: -1;
    top: -1rem;
    bottom: 1.5rem;
    left: -1rem;
    right: -1rem;
    border-radius: 1rem;
    border-top: none;
    background-color: @mediahub-color-black-600;
  }

  &:last-child::before {
    pointer-events: none;
    content: "";
    position: absolute;
    z-index: -2;
    left: 0;
    right: 0;
    bottom: 1rem;
    border-bottom: 0.1rem solid @ds-black-700;
  }

  &:last-child:hover::before {
    border-bottom: none;
  }

  &:hover {
    .mediahub-series-content__episode-card {
      .mediahub-card--hover();
    }
  }

  &:hover + &::after {
    border-top: none;
  }
}

.mediahub-series-content__episode-title {
  grid-area: title;
  .mediahub-header-font(1.6rem, 0.3rem);
  .mediahub-lines-of-text(3);
  padding-left: 5rem;
  min-height: 5rem;
  border-bottom: 1rem solid transparent;

  .mediahub-series-content__episode--selected & {
    color: @ds-yellow;
  }

  @media @xl {
    padding-left: 0;
    min-height: initial;
    border-bottom: none;
  }
}

.mediahub-series-content__episode-text {
  grid-area: text;
  .mediahub-base-font-light-gray(1.4rem);
  .mediahub-lines-of-text(3);

  .mediahub-series-content__episode--open-read-more & {
    .mediahub-all-lines-text();
  }

  @media @xl {
    .mediahub-base-font-light-gray(1.6rem);
    .mediahub-lines-of-text(2);
  }
}

.mediahub-series-content__episode-read-more {
  grid-area: readmore;
  display: none;
  .mediahub-base-font-light-gray(1.2rem);
  margin-right: auto;
  cursor: pointer;
  text-decoration: underline;
  position: relative;
  padding: 0.7rem 2rem 0 0;

  @media @xl {
    .mediahub-base-font-light-gray(1.4rem);
  }

  &::before {
    content: "Vis mere";
    cursor: pointer;
  }

  &::after {
    content: "";
    position: absolute;
    position: relative;
    left: 1.3ch;
    top: -0.2em;
    border: solid @mediahub-color-lightgrey;
    border-width: 0 0.1rem 0.1rem 0;
    display: inline-block;
    padding: 0.2rem;
    transform: rotate(45deg);
  }

  .mediahub-series-content__episode--show-read-more & {
    display: block;
  }

  .mediahub-series-content__episode--show-read-more.mediahub-series-content__episode--open-read-more & {
    &::before {
      content: "Vis mindre";
    }

    &::after {
      top: 0;
      transform: rotate(225deg);
    }
  }
}

.mediahub-series-content__episode-participants {
  grid-area: participants;
  .mediahub-base-font-gray(1.2rem);
  padding: 1.5rem 0 0.5rem;
  margin-right: 9rem;

  a, a:not([class]) {
    .mediahub-base-font-gray(1.2rem);
  }

  @media @xl {
    .mediahub-base-font-gray(1.4rem);

    a, a:not([class]) {
      .mediahub-base-font-gray(1.4rem);
    }
  }
}

.mediahub-series-content__episode-date {
  grid-area: date;
  .mediahub-base-font-gray(1.2rem);
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.9rem;

  @media @xl {
    .mediahub-base-font-gray(1.4rem);
  }
}

.mediahub-series-content__episode-duration {
  grid-area: duration;
  .mediahub-base-font-gray(1.2rem);
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.9rem;
  padding-left: 2rem;

  @media @xl {
    .mediahub-base-font-gray(1.4rem);
  }
}

.mediahub-series-content__episode-cta {
  grid-area: cta;
  margin-top: auto;

  .mediahub-series-content__episode--article & {
    .mediahub-cta-article();
  }

  .mediahub-series-content__episode--podcast & {
    .mediahub-cta-podcast();
  }

  .mediahub-series-content__episode--video & {
    .mediahub-cta-video();
  }
}

.mediahub-series-content__episode-card {
  .mediahub-card();
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 4rem;
  height: 4rem;

  @media @xl {
    width: 12rem;
    height: 12rem;
  }
}

.mediahub-series-content__episode-card-logo {
  .mediahub-card-logo();
}

.mediahub-series-content__episode-card-bg {
  .mediahub-card-bg();

  .mediahub-series-content__episode--article & {
    .mediahub-card-type-article--xs();

    @media @lg {
      .mediahub-card-type-article--sm();
    }
  }

  .mediahub-series-content__episode--podcast & {
    .mediahub-card-type-podcast--xs();

    @media @lg {
      .mediahub-card-type-podcast--sm();
    }
  }

  .mediahub-series-content__episode--video & {
    .mediahub-card-type-video--xs();

    @media @lg {
      .mediahub-card-type-video--sm();
    }
  }
}

////////////////////////////////
// About
////////////////////////////////

.mediahub-series-content-about {
  .mediahub-container();
  position: relative;
}

.mediahub-series-content-about__title {
  grid-area: title;
  .mediahub-header-font(2.8rem, 0.3rem, 3.2rem);
  padding-left: 16.5rem;
  min-height: 16.5rem;

  @media @sm {
    .mediahub-header-font(5rem, 0.3rem, 6rem);
  }
}

.mediahub-series-content-about__text {
  grid-area: text;
  .mediahub-base-font-light-gray(1.6rem);
}

.mediahub-series-content-about__card {
  .mediahub-card();
  position: absolute;
  z-index: 1;
  top: 0;
  left: 1.5rem;
  width: 15rem;
  height: 15rem;
}

.mediahub-series-content-about__card-logo {
  .mediahub-card-logo();
}

.mediahub-series-content-about__card-bg {
  .mediahub-card-bg();

  .mediahub-series-content-about--article & {
    .mediahub-card-type-article--sm();
  }

  .mediahub-series-content-about--podcast & {
    .mediahub-card-type-podcast--sm();
  }

  .mediahub-series-content-about--video & {
    .mediahub-card-type-video--sm();
  }

  .mediahub-series-content-about--article.mediahub-series-content-about--podcast & {
    .mediahub-card-type-article-podcast--sm();
  }

  .mediahub-series-content-about--article.mediahub-series-content-about--video & {
    .mediahub-card-type-article-video--sm();
  }

  .mediahub-series-content-about--podcast.mediahub-series-content-about--video & {
    .mediahub-card-type-podcast-video--sm();
  }

  .mediahub-series-content-about--article.mediahub-series-content-about--podcast.mediahub-series-content-about--video & {
    .mediahub-card-type-article-podcast-video--sm();
  }
}
