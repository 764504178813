@import (reference) "./_imports";

.mediahub-article-text {
  .mediahub-article-container();

  .mediahub-base-font(1.6rem);

  margin-bottom: 3rem;

  p {
    margin-bottom: 2rem;
  }

  h2 {
    .mediahub-header-font(2rem, 1rem, 2.4rem);

    @media @sm {
      .mediahub-header-font(3.2rem, 1.5rem, 3.8rem);
    }
  }

  h3 {
    .mediahub-header-font(1.7rem, 1rem, 2rem);

    @media @sm {
      .mediahub-header-font(2.4rem, 1.5rem, 3rem);
    }
  }
}
