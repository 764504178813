﻿@import (reference) "./_imports";

.mediahub-all-series {
  .mediahub-container();
  padding: 0;
}

.mediahub-all-series__title {
  .mediahub-header-font(1.6rem, 1rem);
  padding: 0 1.5rem;

  @media @lg {
    .mediahub-header-font(2.4rem, 2rem);
  }
}

.mediahub-all-series__contents {
  white-space: nowrap;
  overflow: hidden;
  height: 9.5rem;

  @media @sm {
    height: 17.5rem;
  }

  @media @lg {
    overflow: hidden;
    height: auto;
    white-space: normal;
  }
}

.mediahub-all-series__contents-inner {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 1rem 5rem 1.5rem;

  @media @lg {
    border-radius: 1rem;
    margin: 0 1.5rem;
    background: @mediahub-color-black-650;
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
    display: grid;
    grid-template-columns: repeat(6, 14.5rem);
    grid-template-rows: repeat(auto-fill, 14.5rem);
    column-gap: ~"calc((100% - 6 * 14.5rem) / 5)";
    grid-row-gap: 1.5rem;
    padding: 1.5rem;

    &:hover {
      background: @mediahub-color-black-700;
    }
  }

  @media @xxl {
    grid-row-gap: 2rem;
    margin: 0 1.5rem;
    grid-template-columns: repeat(7, 15rem);
    grid-template-rows: repeat(auto-fill, 15rem);
    column-gap: ~"calc((100% - 7 * 15rem) / 6)";
    padding: 2rem;
  }
}

.mediahub-all-series__series {
  grid-row: 1;
  grid-template-areas: "card";
  white-space: normal;
  position: relative;
  display: inline-grid;
  margin-right: 1rem;
  grid-template-columns: 9.5rem;
  grid-template-rows: 9.5rem;

  @media @sm {
    grid-template-columns: 16rem;
    grid-template-rows: 15.5rem 1fr;
    grid-template-areas: "card" "title";
  }

  @media @lg {
    margin-right: 0;
    grid-template-columns: 14.5rem;
    grid-template-rows: 14.5rem;
    grid-template-areas: "card";

    &:nth-child(n + 7) {
      // There are 6 cards in each row, so card 7 should start on row 2.
      grid-row: 2;
    }

    &:nth-child(n + 13) {
      // 2 rows, with 6 cards in each row, so card 13 should start on row 3.
      grid-row: 3;
    }

    &:nth-child(n + 19) {
      // Just in case there is more than 18 cards.
      grid-row: 4;
    }
  }

  @media @xxl {
    grid-template-columns: 15rem;
    grid-template-rows: 15rem;

    &:nth-child(n + 7) {
      // Overwrite the @lg media query, there's now 7 cards in each row.
      grid-row: 1;
    }

    &:nth-child(n + 8) {
      // There are 7 cards in each row, so card 8 should start on row 2.
      grid-row: 2;
    }

    &:nth-child(n + 15) {
      // 2 rows, with 7 cards in each row, is 14 cards, so card 15 should start on row 3. 
      grid-row: 3;
    }

    &:nth-child(n + 22) {
      // Just in case there is more than 21 cards.
      grid-row: 4;
    }
  }
}

h3.mediahub-all-series__series-title {
  display: none;

  @media @sm {
    display: block;
    .mediahub-base-font(1.2rem);
    .mediahub-lines-of-text(1);
  }

  @media @lg {
    display: none;
  }
}

.mediahub-all-series__series-card {
  grid-area: card;
  .mediahub-card();
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;

  &:hover {
    .mediahub-card--hover();
  }

  @media @sm {
    width: 14.5rem;
    height: 14.5rem;
  }

  @media @xxl {
    width: 15rem;
    height: 15rem;
  }
}

.mediahub-all-series__series-card-logo {
  .mediahub-card-logo();
}

.mediahub-all-series__series-card-bg {
  .mediahub-card-bg();

  .mediahub-all-series__series--article & {
    .mediahub-card-type-article--sm();
  }

  .mediahub-all-series__series--podcast & {
    .mediahub-card-type-podcast--sm();
  }

  .mediahub-all-series__series--video & {
    .mediahub-card-type-video--sm();
  }

  .mediahub-all-series__series--article.mediahub-all-series__series--podcast & {
    .mediahub-card-type-article-podcast--sm();
  }

  .mediahub-all-series__series--article.mediahub-all-series__series--video & {
    .mediahub-card-type-article-video--sm();
  }

  .mediahub-all-series__series--podcast.mediahub-all-series__series--video & {
    .mediahub-card-type-podcast-video--sm();
  }

  .mediahub-all-series__series--article.mediahub-all-series__series--podcast.mediahub-all-series__series--video & {
    .mediahub-card-type-article-podcast-video--sm();
  }
}
