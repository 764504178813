﻿@import (reference) "./../../../_imports";
@import (reference) "./_typography";

.mediahub-card() {
  background-color: @ds-gray-900;
  border-radius: 0.5rem;
  overflow: hidden;
}

.mediahub-card--hover() {
  :first-child {
    transform: scale(96%);
  }

  :last-child {
    background-size: auto 103%;
  }
}

.mediahub-card-logo() {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: transform 200ms ease-out;
}

.mediahub-card-bg() {
  position: relative;
  background-position: center;
  background-size: auto 100%;
  transition: background-size 300ms ease-out;
  width: 100%;
  height: 100%;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    background-repeat: no-repeat;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.mediahub-card-type-article--xs() {
  &::before {
    .mediahub-card-type-article-image();
    background-position: 0.5rem 0.5rem;
    background-size: auto 1rem;
  }
}

.mediahub-card-type-article--sm() {
  &::before {
    .mediahub-card-type-article-image();
    background-position: 0.9rem 0.9rem;
    background-size: auto 1.5rem;
  }
}

.mediahub-card-type-article--md() {
  &::before {
    .mediahub-card-type-article-image();
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
  }
}

.mediahub-card-type-article--lg() {
  .mediahub-header-font(1.6rem);

  &::before {
    .mediahub-card-type-article-image();
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 3.6rem;
    content: "artikel";
  }
}

.mediahub-card-type-podcast--xs() {
  &::before {
    .mediahub-card-type-podcast-image();
    background-position: 0.5rem 0.5rem;
    background-size: auto 1rem;
  }
}

.mediahub-card-type-podcast--sm() {
  &::before {
    .mediahub-card-type-podcast-image();
    background-position: 0.9rem 0.9rem;
    background-size: auto 1.5rem;
  }
}

.mediahub-card-type-podcast--md() {
  &::before {
    .mediahub-card-type-podcast-image();
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
  }
}

.mediahub-card-type-podcast--lg() {
  .mediahub-header-font(1.6rem);

  &::before {
    .mediahub-card-type-podcast-image();
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 3.3rem;
    content: "podcast";
  }
}

.mediahub-card-type-video--xs() {
  &::before {
    .mediahub-card-type-video-image();
    background-position: 0.5rem 0.5rem;
    background-size: auto 1rem;
  }
}

.mediahub-card-type-video--sm() {
  &::before {
    .mediahub-card-type-video-image();
    background-position: 0.9rem 0.9rem;
    background-size: auto 1.5rem;
  }
}

.mediahub-card-type-video--md() {
  &::before {
    .mediahub-card-type-video-image();
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
  }
}

.mediahub-card-type-video--lg() {
  .mediahub-header-font(1.6rem);

  &::before {
    .mediahub-card-type-video-image();
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 4.2rem;
    content: "video";
  }
}

.mediahub-card-type-article-podcast--sm() {
  &::before {
    .mediahub-card-type-image-article-podcast();
    background-size: auto 1.5rem;
    background-position: 0.8rem 0.8rem, 2.6rem 0.8rem;
  }
}

.mediahub-card-type-article-video--sm() {
  &::before {
    .mediahub-card-type-image-article-video();
    background-size: auto 1.5rem;
    background-position: 0.8rem 0.8rem, 2.6rem 0.8rem;
  }
}

.mediahub-card-type-podcast-video--sm() {
  &::before {
    .mediahub-card-type-image-podcast-video();
    background-size: auto 1.5rem;
    background-position: 0.8rem 0.8rem, 2.6rem 0.8rem;
  }
}

.mediahub-card-type-article-podcast-video--sm() {
  &::before {
    .mediahub-card-type-image-article-podcast-video();
    background-size: auto 1.5rem;
    background-position: 0.8rem 0.8rem, 2.6rem 0.8rem, 4.2rem 0.8rem;
  }
}

.mediahub-card-type-article-image() {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
}

.mediahub-card-type-podcast-image() {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
}

.mediahub-card-type-video-image() {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
}

.mediahub-card-type-image-article-video() {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
}

.mediahub-card-type-image-article-podcast() {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
}

.mediahub-card-type-image-podcast-video() {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
}

.mediahub-card-type-image-article-podcast-video() {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
}
