@import (reference) url(../../AvalonComponents/Styles/_imports);
@import './tooltip';

.poker-consent {
  position: relative;
  height: 100vh;
  background-color: @ds-gray-75;
  font-size: 1.6rem;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  h3 {
    text-transform: none;
    font-weight: 700;
  }

  .poker-consent__overlay {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);

    &--show {
      display: block;
    }
  }

  .poker-consent__spinner-wrapper {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--show {
      display: flex;
    }

    .poker-consent__spinner {
      min-height: 7rem;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        content: "";
        background-repeat: no-repeat;
        background-position: center;
        animation: poker-consent-loading-spinner-animation 750ms infinite linear;
        position: absolute;
        z-index: 1;
        top: calc(~'50% - 2rem');
        left: calc(~'50% - 2rem');
        width: 4rem;
        height: 4rem;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='20' cy='4.375' r='3.125' fill='white'/%3E%3Ccircle cx='27.8149' cy='6.48389' r='3.125' fill='white'/%3E%3Ccircle cx='33.5286' cy='12.2182' r='3.125' fill='white'/%3E%3Ccircle cx='35.625' cy='20' r='3.125' fill='white'/%3E%3C/svg%3E");
      }

      @keyframes poker-consent-loading-spinner-animation {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(359deg);
        }
      }

      &--add-new-subscription-game {
        min-height: 22rem;
      }

      &--subscription {
        min-height: 50rem;
      }

      &--terminated-subscriptions {
        min-height: 27rem;
      }
    }

    .poker-consent__please-wait {
      color: @ds-white;
    }
  }

  .poker-consent__rich-text {
    overflow-wrap: break-word;
    overflow: scroll;
    flex: 0 1 100%;

    .poker-consent__tooltip-trigger {
      &::after {
        content: 'i'; 
        display: inline-flex;
        text-decoration: none;
        margin: 0 0.2rem;
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%;
        background-color: @ds-honey-400;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
      }
    }
  }

  .poker-consent__toggle-outer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 25%;

    .poker-consent__toggle-box {
      display: flex;
      justify-content: space-between;
      background-color: @ds-white;
      padding: 1.6rem;
      border-radius: 1.6rem;
      box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.15);


      .poker-consent__toggle {
        position: relative;

        .poker-consent__toggle-background {
          width: 5rem;
          height: 3rem;
          background-color: @ds-gray-800;
          border-radius: 1.5rem;
          transition-duration: 0.1s;
        }

        .poker-consent__toggle-knob {
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          background-color: @ds-gold-600;
          border: @ds-gray-800 solid 0.2rem;
          position: absolute;
          top: 0;
          left: 0;
          transition-duration: 0.1s;
        }

        &--accepted {
          .poker-consent__toggle-background {
            background-color: @ds-black;
          }

          .poker-consent__toggle-knob {
            background-color: @ds-honey-400;
            border: @ds-black solid 0.3rem;
            top: 0;
            left: 2rem;
          }
        }
      }
    }
  }

  .poker-consent__elouqa-error-wrapper {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: @ds-white;
    display: flex;
    flex-direction: column;
    align-items: center;

    .poker-consent__error-arrow {
      width: 6rem;
      height: 6rem;
    }

    .poker-consent__error-button {
      color: @ds-yellow;
      font-weight: 700;
      text-decoration: underline solid;
    }
  }

  .poker-consent__tooltip-content {
    position: absolute;
    background-color: @ds-black;
  }
}

