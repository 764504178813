.ds-rich-text {

  h1, h2, h3, h4, h5, h6 {
    font-family: 'AvenirNext', sans-serif;
    margin: 0 0 1rem;
    font-style: italic;
  }

  h1 {
    font-size: 5.2rem;
    font-weight: 700;
    line-height: 5rem;
  }

  h2 {
    font-size: 4.2rem;
    font-weight: 700;
    line-height: 4rem;
  }

  h3 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: 3rem;
  }

  p {
    margin: 0;
  }

  a {
    color: currentColor;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ul, ol {
    margin-left: -1ch;

    li {
      margin-bottom: 0.5em;
    }
  }

  ul {
    list-style: disc;
    margin-left: 3ch;
  }
}
