span[class^="playingcard-"] {
  width: 1.9em;
  height: 1.2em;
  font-size: inherit;
  line-height: inherit;
  display: inline-block;
  background-size: 1.8em 1em;
  background-color: white;
  border: 0.1rem solid #dbdbdb;
  border-radius: 0.2em;
  margin-bottom: -0.2em;
  margin-left: 0.1em;
}
.playingcard-H2 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#H2_use);
}
.playingcard-H3 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#H3_use);
}
.playingcard-H4 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#H4_use);
}
.playingcard-H5 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#H5_use);
}
.playingcard-H6 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#H6_use);
}
.playingcard-H7 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#H7_use);
}
.playingcard-H8 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#H8_use);
}
.playingcard-H9 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#H9_use);
}
.playingcard-H10 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#H10_use);
}
.playingcard-HA {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#HA_use);
}
.playingcard-HJ {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#HJ_use);
}
.playingcard-HK {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#HK_use);
}
.playingcard-HQ {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#HQ_use);
}
.playingcard-K2 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#K2_use);
}
.playingcard-K3 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#K3_use);
}
.playingcard-K4 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#K4_use);
}
.playingcard-K5 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#K5_use);
}
.playingcard-K6 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#K6_use);
}
.playingcard-K7 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#K7_use);
}
.playingcard-K8 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#K8_use);
}
.playingcard-K9 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#K9_use);
}
.playingcard-K10 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#K10_use);
}
.playingcard-KA {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#KA_use);
}
.playingcard-KJ {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#KJ_use);
}
.playingcard-KK {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#KK_use);
}
.playingcard-KQ {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#KQ_use);
}
.playingcard-R2 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#R2_use);
}
.playingcard-R3 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#R3_use);
}
.playingcard-R4 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#R4_use);
}
.playingcard-R5 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#R5_use);
}
.playingcard-R6 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#R6_use);
}
.playingcard-R7 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#R7_use);
}
.playingcard-R8 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#R8_use);
}
.playingcard-R9 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#R9_use);
}
.playingcard-R10 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#R10_use);
}
.playingcard-RA {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#RA_use);
}
.playingcard-RJ {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#RJ_use);
}
.playingcard-RK {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#RK_use);
}
.playingcard-RQ {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#RQ_use);
}
.playingcard-S2 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#S2_use);
}
.playingcard-S3 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#S3_use);
}
.playingcard-S4 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#S4_use);
}
.playingcard-S5 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#S5_use);
}
.playingcard-S6 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#S6_use);
}
.playingcard-S7 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#S7_use);
}
.playingcard-S8 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#S8_use);
}
.playingcard-S9 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#S9_use);
}
.playingcard-S10 {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#S10_use);
}
.playingcard-SA {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#SA_use);
}
.playingcard-SJ {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#SJ_use);
}
.playingcard-SK {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#SK_use);
}
.playingcard-SQ {
  background-image: url(/Components/DanskeSpil/Domain/Poker/Graphics/SpriteSheets/PlayingCards.svg#SQ_use);
}
