.mediahub-episodes-latest {
  padding: 0 1.5rem;
  max-width: 102.4rem;
  margin: 0 auto 3rem;
}
@media screen and (min-width: 1440px) {
  .mediahub-episodes-latest {
    max-width: 128rem;
    margin: 0 auto 5rem;
  }
}
@media screen and (min-width: 1024px) {
  .mediahub-episodes-latest {
    display: grid;
    grid-template-areas: "description subtitle" "description episodes";
    grid-template-columns: 1fr 48rem;
    grid-column-gap: 5rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-episodes-latest {
    grid-template-columns: 1fr 60rem;
  }
}
.mediahub-episodes-latest__title,
h1.mediahub-episodes-latest__title,
h2.mediahub-episodes-latest__title,
h3.mediahub-episodes-latest__title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 3.2rem;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 2rem;
}
h1.mediahub-episodes-latest__title {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 1024px) {
  .mediahub-episodes-latest__title,
  h1.mediahub-episodes-latest__title,
  h2.mediahub-episodes-latest__title,
  h3.mediahub-episodes-latest__title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 5rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 6rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 2rem;
  }
  h1.mediahub-episodes-latest__title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-episodes-latest__subtitle {
  grid-area: subtitle;
}
.mediahub-episodes-latest__subtitle,
h1.mediahub-episodes-latest__subtitle,
h2.mediahub-episodes-latest__subtitle,
h3.mediahub-episodes-latest__subtitle {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 1.5;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 1rem;
}
h1.mediahub-episodes-latest__subtitle {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 1024px) {
  .mediahub-episodes-latest__subtitle {
    text-align: right;
  }
}
.mediahub-episodes-latest__text {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}
.mediahub-episodes-latest__description {
  margin-bottom: 3rem;
  grid-area: description;
}
@media screen and (min-width: 1024px) {
  .mediahub-episodes-latest__description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 48rem;
  }
}
.mediahub-episodes-latest__episodes {
  grid-area: episodes;
}
@media screen and (min-width: 768px) {
  .mediahub-episodes-latest__episodes {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1024px) {
  .mediahub-episodes-latest__episodes {
    flex-direction: column;
  }
}
.mediahub-episodes-latest__episode {
  display: grid;
  grid-template-areas: "title title" "text text" "date cta";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr;
  position: relative;
  min-height: 14.5rem;
  background-color: #2D2A35;
  padding: 1.5rem 1.5rem 1.5rem 14.5rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
}
.mediahub-episodes-latest__episode:hover {
  background-color: #373341;
}
.mediahub-episodes-latest__episode:hover .mediahub-episodes-latest__episode-card :first-child {
  transform: scale(96%);
}
.mediahub-episodes-latest__episode:hover .mediahub-episodes-latest__episode-card :last-child {
  background-size: auto 103%;
}
@media screen and (min-width: 768px) {
  .mediahub-episodes-latest__episode {
    flex: 0 0 calc(50% - 0.5rem);
    margin-bottom: 0;
  }
}
@media screen and (min-width: 1024px) {
  .mediahub-episodes-latest__episode {
    margin-bottom: 1.5rem;
    flex: 1;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-episodes-latest__episode {
    padding: 1rem 1.5rem 1.3rem 14.5rem;
    grid-template-areas: "title title cta" "text text cta" "date duration cta";
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto 1fr;
  }
}
.mediahub-episodes-latest__episode:last-child {
  margin-bottom: 0;
}
.mediahub-episodes-latest__episode-title {
  grid-area: title;
  height: auto;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mediahub-episodes-latest__episode-title,
h1.mediahub-episodes-latest__episode-title,
h2.mediahub-episodes-latest__episode-title,
h3.mediahub-episodes-latest__episode-title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 1.5;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 0.3rem;
}
h1.mediahub-episodes-latest__episode-title {
  font-weight: 800;
  color: white;
}
.mediahub-episodes-latest__episode-text {
  grid-area: text;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  height: auto;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.mediahub-episodes-latest__episode-date {
  grid-area: date;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.9rem;
}
@media screen and (min-width: 1440px) {
  .mediahub-episodes-latest__episode-date {
    padding-bottom: 0;
    margin-right: 2rem;
    font-size: 1.2rem;
    align-items: flex-end;
  }
}
.mediahub-episodes-latest__episode-duration {
  grid-area: duration;
  display: none;
}
@media screen and (min-width: 1440px) {
  .mediahub-episodes-latest__episode-duration {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.2rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
    display: flex;
    align-items: flex-end;
  }
}
.mediahub-episodes-latest__episode-cta {
  grid-area: cta;
  margin-top: auto;
}
@media screen and (min-width: 1440px) {
  .mediahub-episodes-latest__episode-cta {
    margin: auto auto auto 1rem;
  }
}
.mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-cta {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #000000;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;
}
.mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-cta::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 2rem;
  transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
}
.mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-cta:hover::before {
  left: -0.5rem;
  right: -0.5rem;
  background-color: #E9E8EB;
}
.mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-cta::after {
  content: "";
  margin-left: 0.7rem;
  width: 0.8rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
}
.mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-cta::after {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-read.svg);
}
.mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-cta {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #000000;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;
}
.mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-cta::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 2rem;
  transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
}
.mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-cta:hover::before {
  left: -0.5rem;
  right: -0.5rem;
  background-color: #E9E8EB;
}
.mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-cta::after {
  content: "";
  margin-left: 0.7rem;
  width: 0.8rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
}
.mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-cta::after {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-listen.svg);
}
.mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-cta {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #000000;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;
}
.mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-cta::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 2rem;
  transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
}
.mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-cta:hover::before {
  left: -0.5rem;
  right: -0.5rem;
  background-color: #E9E8EB;
}
.mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-cta::after {
  content: "";
  margin-left: 0.7rem;
  width: 0.8rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
}
.mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-cta::after {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-play.svg);
}
.mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-cta--mobile,
.mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-cta--mobile,
.mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-cta--mobile {
  display: flex;
}
@media screen and (min-width: 1024px) {
  .mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-cta--mobile,
  .mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-cta--mobile,
  .mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-cta--mobile {
    display: none;
  }
}
.mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-cta--desktop,
.mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-cta--desktop,
.mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-cta--desktop {
  display: none;
}
@media screen and (min-width: 1024px) {
  .mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-cta--desktop,
  .mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-cta--desktop,
  .mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-cta--desktop {
    display: flex;
  }
}
.mediahub-episodes-latest__episode-card {
  background-color: #222222;
  border-radius: 0.5rem;
  overflow: hidden;
  width: 11.4rem;
  height: 11.4rem;
  position: absolute;
  z-index: 1;
  top: 1.5rem;
  left: 1.5rem;
}
.mediahub-episodes-latest__episode-card-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: transform 200ms ease-out;
}
.mediahub-episodes-latest__episode-card-bg {
  position: relative;
  background-position: center;
  background-size: auto 100%;
  transition: background-size 300ms ease-out;
  width: 100%;
  height: 100%;
}
.mediahub-episodes-latest__episode-card-bg::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mediahub-episodes-latest__episode--article .mediahub-episodes-latest__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
.mediahub-episodes-latest__episode--podcast .mediahub-episodes-latest__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
.mediahub-episodes-latest__episode--video .mediahub-episodes-latest__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
