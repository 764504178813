﻿.ds-text-component {
  font-size: 1.6rem;
  line-height: 1.5;
  color: currentColor;

  h1 {
    font-size: 4.4rem;
    line-height: 5rem;
    letter-spacing: -0.16rem;
    font-weight: 500;
    font-family: 'DanskeSpil', sans-serif;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      font-size: 8rem;
      line-height: 9.2rem;
      margin-bottom: 2rem;
    }
  }

  h2 {
    font-size: 3.6rem;
    line-height: 4.1rem;
    letter-spacing: -0.12rem;
    font-weight: 500;
    font-family: 'DanskeSpil', sans-serif;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      font-size: 5.6rem;
      line-height: 6.4rem;
      margin-bottom: 2rem;
    }
  }

  h3 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    letter-spacing: -0.08rem;
    font-weight: 500;
    font-family: 'DanskeSpil', sans-serif;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      font-size: 4rem;
      line-height: 4.6rem;
      margin-bottom: 2rem;
    }
  }

  h4 {
    font-size: 2.4rem;
    line-height: 2.5rem;
    letter-spacing: -0.08rem;
    font-weight: 500;
    font-family: 'DanskeSpil', sans-serif;
    margin-bottom: 1rem;

    @media (min-width: 768px) {
      font-size: 3.2rem;
      line-height: 3.6rem;
      margin-bottom: 1.5rem;
    }
  }

  h5, h6 {
    font-size: 2rem;
    line-height: 2.3rem;
    letter-spacing: -0.08rem;
    font-weight: 700;
    font-family: 'DanskeSpil', sans-serif;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      font-size: 2.4rem;
      line-height: 2.7rem;
      margin-bottom: 2rem;
    }
  }

  .title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.5rem;
    letter-spacing: -0.08rem;
    margin-bottom: 2rem;
  }

  .manchet {
    font-size: 2.4rem;
    line-height: 2.8rem;
    letter-spacing: -0.08rem;
  }

  p {
    margin-bottom: 1.8rem;
  }

  a {
    color: currentColor;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ul, ol {
    margin-left: -1ch;
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 2em;

    li {
      margin-bottom: 0.5em;
    }
  }

  ul {
    list-style: disc;
    margin-left: 3ch;
  }

  .variant-5 & {
    color: #002039;

    * {
      color: #002039;
    }
  }

  .variant-6 & {
    color: #fff;

    * {
      color: #fff;
    }
  }
}
