﻿@import (reference) url(../../AvalonComponents/Styles/_imports);

.tooltip {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  background-color: @ds-black;
  border-radius: 0.8rem;
  width: 28.8rem;
  padding: 1rem;
  z-index: 2;

  &--show {
    opacity: 1;
    pointer-events: auto;
  }

  .tooltip__content {
    color: @ds-white;
    font-size: 1.2rem;
  }

  .tooltip__arrow {
    width: 1rem;
    height: 1rem;
    position: absolute;
    background-color: @ds-black;
    z-index: 2;
    bottom: -0.5rem;
    transform: rotate(45deg);
  }
}
