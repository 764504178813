.ds-text-component {
  font-size: 1.6rem;
  line-height: 1.5;
  color: currentColor;
}
.ds-text-component h1 {
  font-size: 4.4rem;
  line-height: 5rem;
  letter-spacing: -0.16rem;
  font-weight: 500;
  font-family: 'DanskeSpil', sans-serif;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .ds-text-component h1 {
    font-size: 8rem;
    line-height: 9.2rem;
    margin-bottom: 2rem;
  }
}
.ds-text-component h2 {
  font-size: 3.6rem;
  line-height: 4.1rem;
  letter-spacing: -0.12rem;
  font-weight: 500;
  font-family: 'DanskeSpil', sans-serif;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .ds-text-component h2 {
    font-size: 5.6rem;
    line-height: 6.4rem;
    margin-bottom: 2rem;
  }
}
.ds-text-component h3 {
  font-size: 2.8rem;
  line-height: 3.2rem;
  letter-spacing: -0.08rem;
  font-weight: 500;
  font-family: 'DanskeSpil', sans-serif;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .ds-text-component h3 {
    font-size: 4rem;
    line-height: 4.6rem;
    margin-bottom: 2rem;
  }
}
.ds-text-component h4 {
  font-size: 2.4rem;
  line-height: 2.5rem;
  letter-spacing: -0.08rem;
  font-weight: 500;
  font-family: 'DanskeSpil', sans-serif;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .ds-text-component h4 {
    font-size: 3.2rem;
    line-height: 3.6rem;
    margin-bottom: 1.5rem;
  }
}
.ds-text-component h5,
.ds-text-component h6 {
  font-size: 2rem;
  line-height: 2.3rem;
  letter-spacing: -0.08rem;
  font-weight: 700;
  font-family: 'DanskeSpil', sans-serif;
  margin-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .ds-text-component h5,
  .ds-text-component h6 {
    font-size: 2.4rem;
    line-height: 2.7rem;
    margin-bottom: 2rem;
  }
}
.ds-text-component .title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: -0.08rem;
  margin-bottom: 2rem;
}
.ds-text-component .manchet {
  font-size: 2.4rem;
  line-height: 2.8rem;
  letter-spacing: -0.08rem;
}
.ds-text-component p {
  margin-bottom: 1.8rem;
}
.ds-text-component a {
  color: currentColor;
  text-decoration: underline;
}
.ds-text-component a:hover {
  text-decoration: none;
}
.ds-text-component ul,
.ds-text-component ol {
  margin-left: -1ch;
  font-size: 1.6rem;
  line-height: 1.5;
  margin-bottom: 2em;
}
.ds-text-component ul li,
.ds-text-component ol li {
  margin-bottom: 0.5em;
}
.ds-text-component ul {
  list-style: disc;
  margin-left: 3ch;
}
.variant-5 .ds-text-component {
  color: #002039;
}
.variant-5 .ds-text-component * {
  color: #002039;
}
.variant-6 .ds-text-component {
  color: #fff;
}
.variant-6 .ds-text-component * {
  color: #fff;
}
