﻿@import (reference) "../../AvalonComponents/Styles/Colors/_colors";

.js-category-entrance .scroller__inner {
  //animation: scroll attr(data-brandlength number, 10s) var(--_animation-direction, forwards) linear infinite;
  animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="default"] {
  --_animation-duration: 40s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
