@import (reference) "./_imports";

.mediahub-episode-content {
  .mediahub-container();
}

.mediahub-episode-content__episode {
  position: relative;
  display: grid;
  grid-template-rows: auto;
  border-radius: 1rem;

  .mediahub-episode-content__episode--article& {
    grid-template-areas: "title title" "participants date";
    grid-template-columns:  1fr auto;
    .mediahub-article-container();
  }

  .mediahub-episode-content__episode--podcast& {
    grid-template-areas: "title title title" "text text text" "participants participants participants" "date duration cta";
    grid-template-columns: auto auto 1fr;
    grid-template-rows: auto auto auto 1fr;
    padding: 1.5rem;
    border-radius: 1rem;
    background-color: @mediahub-color-black-650;

    &:hover {
      .mediahub-episode-content__episode-card {
        .mediahub-card--hover();
      }
    }

    @media @lg {
      padding-left: 24rem;
      min-height: 23rem;
    }
  }

  .mediahub-episode-content__episode--video& {
    grid-template-areas: "video video" "title title" "text text" "participants participants" "date duration";
    grid-template-columns:  auto 1fr;
    padding: 0 0 1.5rem;
    overflow: hidden;
    border-radius: 1rem;
    background-color: @mediahub-color-black-650;
  }
}

.mediahub-episode-content__episode-video {
  grid-area: video;
  position: relative;
  display: none;
  border: none;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 1rem;

  .mediahub-episode-content__episode--video & {
    display: block;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.mediahub-episode-content__episode-title {
  grid-area: title;
  .mediahub-header-font(2.8rem, 1rem, 3.2rem);

  .mediahub-episode-content__episode--article & {
    .mediahub-header-font(2.8rem, 1rem, 3.2rem);

    @media @xl {
      .mediahub-header-font(5rem, 2rem, 6rem);
    }
  }

  .mediahub-episode-content__episode--podcast & {
    min-height: 9rem;
    padding-left: 11rem;

    @media @sm {
      .mediahub-header-font(3.2rem, 2rem, 3.8rem);
    }

    @media @lg {
      .mediahub-header-font(3.2rem, 1rem, 3.8rem);
      padding-left: 0;
      min-height: initial;
    }
  }

  .mediahub-episode-content__episode--video & {
    .mediahub-header-font(2.8rem, 1rem, 3.2rem);
    padding: 0 1.5rem;

    @media @xl {
      .mediahub-header-font(5rem, 2rem, 6rem);
    }
  }
}

.mediahub-episode-content__episode-text {
  grid-area: text;
  .mediahub-base-font-light-gray(1.4rem);

  @media @xl {
    .mediahub-base-font-light-gray(1.6rem);
  }

  .mediahub-episode-content__episode--article & {
    display: none;
  }

  .mediahub-episode-content__episode--video & {
    padding: 0 1.5rem;
  }
}

.mediahub-episode-content__episode-participants {
  grid-area: participants;
  .mediahub-base-font-gray(1.2rem);

  a {
    .mediahub-base-font-gray(1.2rem);
  }

  @media @xl {
    .mediahub-base-font-gray(1.4rem);

    a:not([class]) {
      .mediahub-base-font-gray(1.4rem);
    }
  }

  span {
    padding-right: 1ch;

    .mediahub-episode-content__episode--article & {
      .mediahub-base-font-light-gray(1.2rem);
      font-style: italic;
    }
  }

  .mediahub-episode-content__episode--article & {
    @media @xl {
      .mediahub-base-font-light-gray-bold(1.3rem);
    }
  }

  .mediahub-episode-content__episode--podcast & {
    padding: 1rem 0;
  }

  .mediahub-episode-content__episode--video & {
    padding: 1rem 1.5rem;
  }
}

.mediahub-episode-content__episode-date {
  grid-area: date;
  .mediahub-base-font-gray(1.2rem);
  display: flex;
  align-items: flex-end;

  @media @xl {
    .mediahub-base-font-gray(1.4rem);
  }

  .mediahub-episode-content__episode--article & {
    padding-left: 2rem;
  }

  .mediahub-episode-content__episode--podcast & {
    padding: 1rem 0;
  }

  .mediahub-episode-content__episode--video & {
    padding-left: 1.5rem;
  }
}

.mediahub-episode-content__episode-duration {
  grid-area: duration;
  margin-left: 2rem;
  display: flex;
  align-items: flex-end;
  .mediahub-base-font-gray(1.2rem);

  @media @xl {
    .mediahub-base-font-gray(1.4rem);
  }

  .mediahub-episode-content__episode--article & {
    display: none;
  }

  .mediahub-episode-content__episode--podcast & {
    padding: 1rem 0;
  }
}

.mediahub-episode-content__episode-cta {
  grid-area: cta;
  margin: auto 0 0 auto;

  .mediahub-episode-content__episode--article & {
    display: none;
  }

  .mediahub-episode-content__episode--podcast & {
    .mediahub-cta-podcast();
  }

  .mediahub-episode-content__episode--video & {
    display: none;
  }

  .is-mobile-detected &--desktop,
  .is-tablet-detected &--desktop {
    display: none;
  }

  .is-desktop-detected &--mobile {
    display: none;
  }
}

.mediahub-episode-content__episode-card {
  .mediahub-card();
  display: none;
  width: 9.5rem;
  height: 9.5rem;
  position: absolute;
  z-index: 1;
  top: 1.5rem;
  left: 1.5rem;

  .mediahub-episode-content__episode--podcast & {
    display: block;

    @media @lg {
      width: 20rem;
      height: 20rem;
    }
  }
}

.mediahub-episode-content__episode-card-logo {
  .mediahub-card-logo();
}

.mediahub-episode-content__episode-card-bg {
  .mediahub-card-bg();

  .mediahub-episode-content__episode--article & {
    .mediahub-card-type-article--sm();

    @media @lg {
      .mediahub-card-type-article--lg();
    }
  }

  .mediahub-episode-content__episode--podcast & {
    .mediahub-card-type-podcast--sm();

    @media @lg {
      .mediahub-card-type-podcast--lg();
    }
  }

  .mediahub-episode-content__episode--video & {
    .mediahub-card-type-video--sm();

    @media @lg {
      .mediahub-card-type-video--lg();
    }
  }
}
