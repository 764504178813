.mediahub-all-series {
  padding: 0 1.5rem;
  max-width: 102.4rem;
  margin: 0 auto 3rem;
  padding: 0;
}
@media screen and (min-width: 1440px) {
  .mediahub-all-series {
    max-width: 128rem;
    margin: 0 auto 5rem;
  }
}
.mediahub-all-series__title {
  padding: 0 1.5rem;
}
.mediahub-all-series__title,
h1.mediahub-all-series__title,
h2.mediahub-all-series__title,
h3.mediahub-all-series__title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 1.5;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 1rem;
}
h1.mediahub-all-series__title {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 1024px) {
  .mediahub-all-series__title,
  h1.mediahub-all-series__title,
  h2.mediahub-all-series__title,
  h3.mediahub-all-series__title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 2.4rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 2rem;
  }
  h1.mediahub-all-series__title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-all-series__contents {
  white-space: nowrap;
  overflow: hidden;
  height: 9.5rem;
}
@media screen and (min-width: 768px) {
  .mediahub-all-series__contents {
    height: 17.5rem;
  }
}
@media screen and (min-width: 1024px) {
  .mediahub-all-series__contents {
    overflow: hidden;
    height: auto;
    white-space: normal;
  }
}
.mediahub-all-series__contents-inner {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 1rem 5rem 1.5rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-all-series__contents-inner {
    border-radius: 1rem;
    margin: 0 1.5rem;
    background: #2D2A35;
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
    display: grid;
    grid-template-columns: repeat(6, 14.5rem);
    grid-template-rows: repeat(auto-fill, 14.5rem);
    column-gap: calc((100% - 6 * 14.5rem) / 5);
    grid-row-gap: 1.5rem;
    padding: 1.5rem;
  }
  .mediahub-all-series__contents-inner:hover {
    background: #373341;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-all-series__contents-inner {
    grid-row-gap: 2rem;
    margin: 0 1.5rem;
    grid-template-columns: repeat(7, 15rem);
    grid-template-rows: repeat(auto-fill, 15rem);
    column-gap: calc((100% - 7 * 15rem) / 6);
    padding: 2rem;
  }
}
.mediahub-all-series__series {
  grid-row: 1;
  grid-template-areas: "card";
  white-space: normal;
  position: relative;
  display: inline-grid;
  margin-right: 1rem;
  grid-template-columns: 9.5rem;
  grid-template-rows: 9.5rem;
}
@media screen and (min-width: 768px) {
  .mediahub-all-series__series {
    grid-template-columns: 16rem;
    grid-template-rows: 15.5rem 1fr;
    grid-template-areas: "card" "title";
  }
}
@media screen and (min-width: 1024px) {
  .mediahub-all-series__series {
    margin-right: 0;
    grid-template-columns: 14.5rem;
    grid-template-rows: 14.5rem;
    grid-template-areas: "card";
  }
  .mediahub-all-series__series:nth-child(n + 7) {
    grid-row: 2;
  }
  .mediahub-all-series__series:nth-child(n + 13) {
    grid-row: 3;
  }
  .mediahub-all-series__series:nth-child(n + 19) {
    grid-row: 4;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-all-series__series {
    grid-template-columns: 15rem;
    grid-template-rows: 15rem;
  }
  .mediahub-all-series__series:nth-child(n + 7) {
    grid-row: 1;
  }
  .mediahub-all-series__series:nth-child(n + 8) {
    grid-row: 2;
  }
  .mediahub-all-series__series:nth-child(n + 15) {
    grid-row: 3;
  }
  .mediahub-all-series__series:nth-child(n + 22) {
    grid-row: 4;
  }
}
h3.mediahub-all-series__series-title {
  display: none;
}
@media screen and (min-width: 768px) {
  h3.mediahub-all-series__series-title {
    display: block;
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.2rem;
    color: white;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
    height: auto;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
@media screen and (min-width: 1024px) {
  h3.mediahub-all-series__series-title {
    display: none;
  }
}
.mediahub-all-series__series-card {
  grid-area: card;
  background-color: #222222;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.mediahub-all-series__series-card:hover :first-child {
  transform: scale(96%);
}
.mediahub-all-series__series-card:hover :last-child {
  background-size: auto 103%;
}
@media screen and (min-width: 768px) {
  .mediahub-all-series__series-card {
    width: 14.5rem;
    height: 14.5rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-all-series__series-card {
    width: 15rem;
    height: 15rem;
  }
}
.mediahub-all-series__series-card-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: transform 200ms ease-out;
}
.mediahub-all-series__series-card-bg {
  position: relative;
  background-position: center;
  background-size: auto 100%;
  transition: background-size 300ms ease-out;
  width: 100%;
  height: 100%;
}
.mediahub-all-series__series-card-bg::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mediahub-all-series__series--article .mediahub-all-series__series-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
.mediahub-all-series__series--podcast .mediahub-all-series__series-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
.mediahub-all-series__series--video .mediahub-all-series__series-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
.mediahub-all-series__series--article.mediahub-all-series__series--podcast .mediahub-all-series__series-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
  background-size: auto 1.5rem;
  background-position: 0.8rem 0.8rem, 2.6rem 0.8rem;
}
.mediahub-all-series__series--article.mediahub-all-series__series--video .mediahub-all-series__series-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-size: auto 1.5rem;
  background-position: 0.8rem 0.8rem, 2.6rem 0.8rem;
}
.mediahub-all-series__series--podcast.mediahub-all-series__series--video .mediahub-all-series__series-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-size: auto 1.5rem;
  background-position: 0.8rem 0.8rem, 2.6rem 0.8rem;
}
.mediahub-all-series__series--article.mediahub-all-series__series--podcast.mediahub-all-series__series--video .mediahub-all-series__series-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg), url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-size: auto 1.5rem;
  background-position: 0.8rem 0.8rem, 2.6rem 0.8rem, 4.2rem 0.8rem;
}
