.ds-regionspots {
  display: grid;
  gap: 1.6rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
}
@media screen and (min-width: 1024px) {
  .ds-regionspots {
    gap: 4rem;
    grid-template-columns: repeat(4, 1fr);
  }
}
.ds-regionspot {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem 0.8rem 0.6rem 0.6rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-shadow: 0 0.1rem 0.4rem 0 #0000001A;
  aspect-ratio: 0.75;
}
@media screen and (min-width: 768px) {
  .ds-regionspot--mega {
    aspect-ratio: 2;
  }
}
@media screen and (min-width: 1024px) {
  .ds-regionspot--mega {
    grid-column: 1 / span 4;
    aspect-ratio: 2;
  }
}
.ds-regionspot--medium {
  aspect-ratio: 1.64285714;
}
.ds-regionspot__pool {
  position: absolute;
  inset: 0 0 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.02em;
  color: white;
  white-space: nowrap;
  text-align: center;
  font-size: 1rem;
  line-height: 1;
}
.ds-regionspot--mega .ds-regionspot__pool {
  inset: 0 0 3rem;
  font-size: 1.6rem;
}
.ds-regionspot--medium .ds-regionspot__pool {
  font-size: 1.3rem;
}
@media screen and (min-width: 768px) {
  .ds-regionspot__pool {
    font-size: 1.8rem;
  }
  .ds-regionspot--mega .ds-regionspot__pool {
    font-size: 1.8rem;
  }
  .ds-regionspot__pool .ds-regionspot--medium {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 1024px) {
  .ds-regionspot__pool {
    font-size: 1.3rem;
  }
  .ds-regionspot--mega .ds-regionspot__pool {
    font-size: 2.5rem;
  }
  .ds-regionspot--medium .ds-regionspot__pool {
    font-size: 1.6rem;
  }
}
.ds-regionspot__pool-title {
  opacity: 0.8;
  text-transform: uppercase;
  font-size: 1em;
  margin-bottom: 0.2em;
  font-weight: 700;
  letter-spacing: 0.02em;
}
.ds-regionspot__pool-fallback-postfix {
  font-size: 1.8em;
  font-weight: 800;
  opacity: 0.8;
  letter-spacing: -0.02em;
  margin-bottom: 0.2em;
}
.ds-regionspot__pool-size {
  text-wrap: pretty;
  white-space: nowrap;
  font-size: 3.2em;
  line-height: 1.1;
  font-weight: 800;
  text-transform: uppercase;
}
.ds-regionspot--mega .ds-regionspot__pool-size {
  font-size: 4.3em;
}
.ds-regionspot--medium .ds-regionspot__pool-size {
  font-size: 4em;
}
@media screen and (min-width: 768px) {
  .ds-regionspot--medium .ds-regionspot__pool-size {
    font-size: 6em;
  }
}
@media screen and (min-width: 1024px) {
  .ds-regionspot--mega .ds-regionspot__pool-size {
    font-size: 4.3em;
  }
  .ds-regionspot--medium .ds-regionspot__pool-size {
    font-size: 4.3em;
  }
}
.ds-regionspot__flip-clock {
  margin: 0.8em 0 0.5em;
  font-size: 0.75em;
}
.ds-regionspot__oddset {
  position: absolute;
  inset: 1rem 0 5rem;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-style: italic;
  text-transform: uppercase;
  font-family: AvenirNext, Impact, 'Anton Regular', Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 1;
  text-align: center;
}
.ds-regionspot--feed-oddset .ds-regionspot__oddset[data-fetch="success"] {
  display: flex;
}
.ds-regionspot--mega .ds-regionspot__oddset {
  font-size: 3rem;
}
.ds-regionspot--medium .ds-regionspot__oddset {
  font-size: 2.4rem;
}
@media screen and (min-width: 768px) {
  .ds-regionspot__oddset {
    font-size: 3rem;
  }
  .ds-regionspot--mega .ds-regionspot__oddset {
    font-size: 4rem;
  }
  .ds-regionspot--medium .ds-regionspot__oddset {
    font-size: 4rem;
  }
}
@media screen and (min-width: 1024px) {
  .ds-regionspot__oddset {
    font-size: 2rem;
  }
  .ds-regionspot--mega .ds-regionspot__oddset {
    font-size: 5rem;
  }
  .ds-regionspot--medium .ds-regionspot__oddset {
    font-size: 3rem;
  }
}
.ds-regionspot__oddset-team-name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 1rem;
}
.ds-regionspot__oddset-team-separator {
  line-height: 0.4em;
  font-size: 1.4em;
  margin-bottom: 0.2em;
}
.ds-regionspot__oddset-odds {
  margin-top: 1.6em;
  display: flex;
  gap: 1em;
  font-size: 0.55em;
  line-height: 1;
}
.ds-regionspot--mega .ds-regionspot__oddset-odds {
  font-size: 0.55em;
}
.ds-regionspot--medium .ds-regionspot__oddset-odds {
  font-size: 0.55em;
}
@media screen and (min-width: 768px) {
  .ds-regionspot__oddset-odds {
    font-size: 0.6em;
  }
}
@media screen and (min-width: 1024px) {
  .ds-regionspot__oddset-odds {
    font-size: 0.65em;
  }
  .ds-regionspot--mega .ds-regionspot__oddset-odds {
    font-size: 0.52em;
  }
  .ds-regionspot--medium .ds-regionspot__oddset-odds {
    font-size: 0.52em;
  }
}
.ds-regionspot__oddset-odds-1,
.ds-regionspot__oddset-odds-x,
.ds-regionspot__oddset-odds-2 {
  position: relative;
  padding-left: 1.8em;
}
.ds-regionspot__oddset-odds-1::before,
.ds-regionspot__oddset-odds-x::before,
.ds-regionspot__oddset-odds-2::before {
  position: absolute;
  left: 0;
  top: -0.3em;
  width: 1.4em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0.1em;
  background: #FF7411;
  content: "1";
}
.ds-regionspot__oddset-odds-x::before {
  padding-right: 0.2em;
  content: "X";
}
.ds-regionspot__oddset-odds-2::before {
  content: "2";
}
.ds-regionspot__fg {
  position: absolute;
  inset: 2.5rem 1rem 6.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ds-regionspot:has([data-fetch="loading"]) .ds-regionspot__fg,
.ds-regionspot:has([data-fetch="success"]) .ds-regionspot__fg {
  display: none;
}
.ds-regionspot--mega .ds-regionspot__fg {
  inset: 10.2rem 1.5rem;
}
.ds-regionspot--medium .ds-regionspot__fg {
  inset: 2.4rem 2rem 5.6rem;
}
@media screen and (min-width: 768px) {
  .ds-regionspot__fg {
    inset: 2.5rem 2rem 6.5rem;
  }
  .ds-regionspot--mega .ds-regionspot__fg {
    inset: 9rem 4rem 10.2rem;
  }
  .ds-regionspot--medium .ds-regionspot__fg {
    inset: 4rem 4rem 8rem;
  }
}
@media screen and (min-width: 1024px) {
  .ds-regionspot__fg {
    inset: 2.5rem 1rem 6.5rem;
  }
  .ds-regionspot--mega .ds-regionspot__fg {
    inset: 11.8rem 8rem 12.2rem;
  }
  .ds-regionspot--medium .ds-regionspot__fg {
    inset: 3.5rem 3rem 7.5rem;
  }
}
.ds-regionspot:has(.ds-regionspot__pool) .ds-regionspot__fg {
  display: none;
}
.ds-regionspot__fg picture,
.ds-regionspot__fg img {
  width: 100%;
  height: 100%;
  line-height: 0;
}
.ds-regionspot__fg img {
  object-fit: contain;
}
.ds-regionspot__bg {
  background-color: var(--background-color, transparent);
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  overflow: hidden;
}
.ds-regionspot__bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ds-regionspot__duty-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 4rem;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.9rem 1rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1.2;
  font-weight: 600;
  color: #222222;
}
.ds-regionspot--mega .ds-regionspot__duty-text {
  background: none;
  bottom: auto;
  top: 3rem;
  color: white;
  text-align: center;
  font-size: 0.8rem;
  height: auto;
  padding: 0;
}
.ds-regionspot--mega .ds-regionspot__duty-text .ds-regionspot__brand-logo {
  margin: 0 auto 0.5rem;
  height: 2.4rem;
  background-position: center;
  transform-origin: center;
}
.ds-regionspot__brand-logo {
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center left;
  width: 100%;
  height: 1.15rem;
  transform-origin: left;
  margin-bottom: 0.3rem;
}
.ds-regionspot--allornothing .ds-regionspot__brand-logo {
  transform: scale(1.15) translateY(-20%);
}
.ds-regionspot--bingo .ds-regionspot__brand-logo {
  transform: scale(1.15);
}
.ds-regionspot--casino .ds-regionspot__brand-logo {
  transform: scale(0.75) translateY(-20%);
}
.ds-regionspot--dantoto .ds-regionspot__brand-logo {
  transform: scale(0.9) translateY(-10%);
}
.ds-regionspot--eurojackpot .ds-regionspot__brand-logo {
  transform: scale(1.01) translateY(-5%);
}
.ds-regionspot--keno .ds-regionspot__brand-logo {
  transform: scale(0.9) translateY(-8%);
}
.ds-regionspot--klasselotteriet .ds-regionspot__brand-logo {
  transform: scale(1.3);
}
.ds-regionspot--livecasino .ds-regionspot__brand-logo,
.ds-regionspot--live-casino .ds-regionspot__brand-logo {
  transform: scale(0.8);
}
.ds-regionspot--lotto .ds-regionspot__brand-logo {
  transform: scale(0.92) translateY(-10%);
}
.ds-regionspot--oddset .ds-regionspot__brand-logo {
  transform: scale(1.2) translateY(-10%);
}
.ds-regionspot--plus .ds-regionspot__brand-logo {
  transform: scale(1.5) translateY(-10%);
}
.ds-regionspot--poker .ds-regionspot__brand-logo {
  transform: scale(0.75);
}
.ds-regionspot--quick .ds-regionspot__brand-logo {
  transform: scale(1.2) translateY(0%);
}
.ds-regionspot--spillehjoernet .ds-regionspot__brand-logo {
  transform: scale(3) translateY(-2%);
}
.ds-regionspot--spilsammen .ds-regionspot__brand-logo {
  transform: scale(1.4) translateY(-5%);
}
.ds-regionspot--tips .ds-regionspot__brand-logo {
  transform: scale(0.8) translateY(-10%);
}
.ds-regionspot--tivolicasino .ds-regionspot__brand-logo,
.ds-regionspot--tivoli-casino .ds-regionspot__brand-logo {
  transform: scale(1.2) translateY(-14%);
}
.ds-regionspot--vikinglotto .ds-regionspot__brand-logo {
  transform: scale(1.2);
}
.ds-regionspot--allornothing .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/allornothing.svg");
}
.ds-regionspot--mega.ds-regionspot--allornothing .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/allornothing-white.svg");
}
.ds-regionspot--bingo .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/bingo.svg");
}
.ds-regionspot--mega.ds-regionspot--bingo .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/bingo-white.svg");
}
.ds-regionspot--casino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/casino.svg");
}
.ds-regionspot--mega.ds-regionspot--casino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/casino-white.svg");
}
.ds-regionspot--dantoto .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/dantoto.svg");
}
.ds-regionspot--mega.ds-regionspot--dantoto .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/dantoto-white.svg");
}
.ds-regionspot--eurojackpot .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/eurojackpot.svg");
}
.ds-regionspot--mega.ds-regionspot--eurojackpot .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/eurojackpot-white.svg");
}
.ds-regionspot--keno .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/keno.svg");
}
.ds-regionspot--mega.ds-regionspot--keno .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/keno-white.svg");
}
.ds-regionspot--klasselotteriet .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/klasselotteriet.svg");
}
.ds-regionspot--mega.ds-regionspot--klasselotteriet .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/klasselotteriet-white.svg");
}
.ds-regionspot--livecasino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/livecasino.svg");
}
.ds-regionspot--mega.ds-regionspot--livecasino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/livecasino-white.svg");
}
.ds-regionspot--live-casino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/live-casino.svg");
}
.ds-regionspot--mega.ds-regionspot--live-casino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/live-casino-white.svg");
}
.ds-regionspot--lotto .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/lotto.svg");
}
.ds-regionspot--mega.ds-regionspot--lotto .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/lotto-white.svg");
}
.ds-regionspot--oddset .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/oddset.svg");
}
.ds-regionspot--mega.ds-regionspot--oddset .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/oddset-white.svg");
}
.ds-regionspot--plus .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/plus.svg");
}
.ds-regionspot--mega.ds-regionspot--plus .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/plus-white.svg");
}
.ds-regionspot--poker .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/poker.svg");
}
.ds-regionspot--mega.ds-regionspot--poker .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/poker-white.svg");
}
.ds-regionspot--quick .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/quick.svg");
}
.ds-regionspot--mega.ds-regionspot--quick .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/quick-white.svg");
}
.ds-regionspot--spillehjoernet .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/spillehjoernet.svg");
}
.ds-regionspot--mega.ds-regionspot--spillehjoernet .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/spillehjoernet-white.svg");
}
.ds-regionspot--spilsammen .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/spilsammen.svg");
}
.ds-regionspot--mega.ds-regionspot--spilsammen .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/spilsammen-white.svg");
}
.ds-regionspot--tips .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/tips.svg");
}
.ds-regionspot--mega.ds-regionspot--tips .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/tips-white.svg");
}
.ds-regionspot--tivolicasino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/tivolicasino.svg");
}
.ds-regionspot--mega.ds-regionspot--tivolicasino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/tivolicasino-white.svg");
}
.ds-regionspot--tivoli-casino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/tivoli-casino.svg");
}
.ds-regionspot--mega.ds-regionspot--tivoli-casino .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/tivoli-casino-white.svg");
}
.ds-regionspot--vikinglotto .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/vikinglotto.svg");
}
.ds-regionspot--mega.ds-regionspot--vikinglotto .ds-regionspot__brand-logo {
  background-image: url("/Components/DanskeSpil/Domain/Feature.Components/Graphics/BrandLogos/vikinglotto-white.svg");
}
.ds-regionspot__cta {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 3rem;
  background: #FEB700;
  height: 4rem;
  border-radius: 100rem;
  justify-content: center;
  align-items: center;
  width: calc(100% - 5.2rem);
  max-width: 27rem;
  margin: 0 auto;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 800;
  text-transform: uppercase;
  color: black;
  letter-spacing: 0.1em;
  padding: 0 4rem;
  white-space: nowrap;
  transition: background 300ms;
}
.ds-regionspot__cta:hover {
  background: #eaa800;
}
@media screen and (min-width: 1024px) {
  .ds-regionspot__cta {
    bottom: 4rem;
    height: 5.6rem;
    width: unset;
  }
}
.ds-regionspot--mega .ds-regionspot__cta {
  display: flex;
}
