/* ===========================================
   imports
   ========================================= */
/* ********* Variables ********* */
/* ===========================================
   settings.global
   ========================================= */
/**
 * High-level settings and variables,
 * that are accessible in whole Avalon project
*/
/* ===========================================
   settings.breakpoints
   ========================================= */
/* ===========================================
   settings.colors
   ========================================= */
/* New theming colors (use these in future, convert old colors along the way)
   ========================================================================== */
/* Color variables used in AvalonComponents, Poker and Casino projects
/* (Old theming colors, should be slowly removed)
   ========================================================================== */
/* Corporate colors
   ========================================================================== */
/* Poker colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Bingo colors
   ========================================================================== */
/* Casino colors
   ========================================================================== */
/* Oddset colors
   ========================================================================== */
/* Virtuel colors
   ========================================================================== */
/* EuroJackpot colors
   ========================================================================== */
/* Set Color Variables
   ========================================================================== */
/* Components
   ========================================================================== */
/* *********** MIXINS ********** */
/* ===========================================
   tools.mixins
   ========================================= */
.app-compliance-bar-casino {
  position: fixed;
  inset: 0;
  bottom: unset;
  height: 3.2rem;
  background: white;
}
.app-compliance-bar-casino__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.app-compliance-bar-casino__counter {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding-left: 0.6rem;
}
.app-compliance-bar-casino__counter a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  gap: 0.4rem;
  font-family: 'Raleway', Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  color: #282828;
  text-decoration: none;
}
.app-compliance-bar-casino__counter .app-compliance-bar-casino__counter-clock {
  transition: transform 0.3s ease;
}
.app-compliance-bar-casino__counter .app-compliance-bar-casino__counter-clock--hidden {
  transform: translateY(-3.2rem);
}
.app-compliance-bar-casino__counter img {
  width: 1.8rem;
  height: 1.8rem;
}
.app-compliance-bar-casino__compliance-link {
  height: 100%;
}
.app-compliance-bar-casino__compliance-link a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}
.app-compliance-bar-casino__compliance-link img {
  height: 2rem;
  width: auto;
}
.app-compliance-bar-casino__balance {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 1.6rem;
  font-family: 'Raleway', Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  color: #282828;
  transition: transform 0.3s ease;
}
.app-compliance-bar-casino__balance--hidden {
  transform: translateY(-3.2rem);
}
