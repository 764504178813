﻿@import url("../variables.less");

body.region-poker {
  background-color: #12121A;

}

//.top-navigation > nav:first-of-type:not(:last-of-type) .info-bar-item:hover

.top-navigation-theme-poker {

  @theme-color-a: #443E4C;
  @theme-color-b: #12121A;

  .menu-toggle-right {
    background-color: @theme-color-b;
  }

  .brand-nav {
    background-color: @theme-color-b;
  }

  .corporate-nav {
    background-color: @theme-color-a;

    &__list-item {
      &:not(.js-has-subnav) {
        .corporate-nav__list-link {
          &:hover {
            color: white !important;
          }
        }
      }
    }

  }

  .brand-nav {
    .mobile-navigation {
      &-header {
        background-color: @theme-color-b;
      }

      &-footer {
        &-icon {
          background-color: @theme-color-b;
        }
      }
    }

    &__item {

      &:not(.js-has-subnav) {
        .brand-nav__link {
          &:hover {
            @media all and (min-width: @nav-medium) {
              color: white !important;
            }
          }
        }
      }
    }

    &__link {
      &:before {
        background-color: @theme-color-b;
      }
    }

  }


  .mega-menu {
    &__campaign {
      &__cta-button {
        background-color: @theme-color-b;
        color: white;

        &:hover {
          background-color: @theme-color-a;
        }
      }
    }
  }
}
