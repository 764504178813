.mediahub-episode-content {
  padding: 0 1.5rem;
  max-width: 102.4rem;
  margin: 0 auto 3rem;
}
@media screen and (min-width: 1440px) {
  .mediahub-episode-content {
    max-width: 128rem;
    margin: 0 auto 5rem;
  }
}
.mediahub-episode-content__episode {
  position: relative;
  display: grid;
  grid-template-rows: auto;
  border-radius: 1rem;
}
.mediahub-episode-content__episode--article.mediahub-episode-content__episode {
  grid-template-areas: "title title" "participants date";
  grid-template-columns: 1fr auto;
  padding: 0 1.5rem;
  max-width: 57rem;
  margin: 0 auto;
}
@media screen and (min-width: 768px) {
  .mediahub-episode-content__episode--article.mediahub-episode-content__episode {
    padding: 0;
    max-width: 60rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-episode-content__episode--article.mediahub-episode-content__episode {
    max-width: 64rem;
  }
}
.mediahub-episode-content__episode--podcast.mediahub-episode-content__episode {
  grid-template-areas: "title title title" "text text text" "participants participants participants" "date duration cta";
  grid-template-columns: auto auto 1fr;
  grid-template-rows: auto auto auto 1fr;
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: #2D2A35;
}
.mediahub-episode-content__episode--podcast.mediahub-episode-content__episode:hover .mediahub-episode-content__episode-card :first-child {
  transform: scale(96%);
}
.mediahub-episode-content__episode--podcast.mediahub-episode-content__episode:hover .mediahub-episode-content__episode-card :last-child {
  background-size: auto 103%;
}
@media screen and (min-width: 1024px) {
  .mediahub-episode-content__episode--podcast.mediahub-episode-content__episode {
    padding-left: 24rem;
    min-height: 23rem;
  }
}
.mediahub-episode-content__episode--video.mediahub-episode-content__episode {
  grid-template-areas: "video video" "title title" "text text" "participants participants" "date duration";
  grid-template-columns: auto 1fr;
  padding: 0 0 1.5rem;
  overflow: hidden;
  border-radius: 1rem;
  background-color: #2D2A35;
}
.mediahub-episode-content__episode-video {
  grid-area: video;
  position: relative;
  display: none;
  border: none;
  overflow: hidden;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 1rem;
}
.mediahub-episode-content__episode--video .mediahub-episode-content__episode-video {
  display: block;
}
.mediahub-episode-content__episode-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.mediahub-episode-content__episode-title {
  grid-area: title;
}
.mediahub-episode-content__episode-title,
h1.mediahub-episode-content__episode-title,
h2.mediahub-episode-content__episode-title,
h3.mediahub-episode-content__episode-title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 3.2rem;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 1rem;
}
h1.mediahub-episode-content__episode-title {
  font-weight: 800;
  color: white;
}
.mediahub-episode-content__episode--article .mediahub-episode-content__episode-title,
h1.mediahub-episode-content__episode--article .mediahub-episode-content__episode-title,
h2.mediahub-episode-content__episode--article .mediahub-episode-content__episode-title,
h3.mediahub-episode-content__episode--article .mediahub-episode-content__episode-title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 3.2rem;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 1rem;
}
h1.mediahub-episode-content__episode--article .mediahub-episode-content__episode-title {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 1216px) {
  .mediahub-episode-content__episode--article .mediahub-episode-content__episode-title,
  h1.mediahub-episode-content__episode--article .mediahub-episode-content__episode-title,
  h2.mediahub-episode-content__episode--article .mediahub-episode-content__episode-title,
  h3.mediahub-episode-content__episode--article .mediahub-episode-content__episode-title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 5rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 6rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 2rem;
  }
  h1.mediahub-episode-content__episode--article .mediahub-episode-content__episode-title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title {
  min-height: 9rem;
  padding-left: 11rem;
}
@media screen and (min-width: 768px) {
  .mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title,
  h1.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title,
  h2.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title,
  h3.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 3.2rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 3.8rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 2rem;
  }
  h1.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title {
    font-weight: 800;
    color: white;
  }
}
@media screen and (min-width: 1024px) {
  .mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title {
    padding-left: 0;
    min-height: initial;
  }
  .mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title,
  h1.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title,
  h2.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title,
  h3.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 3.2rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 3.8rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 1rem;
  }
  h1.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title {
  padding: 0 1.5rem;
}
.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title,
h1.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title,
h2.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title,
h3.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 3.2rem;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 1rem;
}
h1.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 1216px) {
  .mediahub-episode-content__episode--video .mediahub-episode-content__episode-title,
  h1.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title,
  h2.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title,
  h3.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 5rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 6rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 2rem;
  }
  h1.mediahub-episode-content__episode--video .mediahub-episode-content__episode-title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-episode-content__episode-text {
  grid-area: text;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}
@media screen and (min-width: 1216px) {
  .mediahub-episode-content__episode-text {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.6rem;
    color: #E9E8EB;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-episode-content__episode--article .mediahub-episode-content__episode-text {
  display: none;
}
.mediahub-episode-content__episode--video .mediahub-episode-content__episode-text {
  padding: 0 1.5rem;
}
.mediahub-episode-content__episode-participants {
  grid-area: participants;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}
.mediahub-episode-content__episode-participants a {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}
@media screen and (min-width: 1216px) {
  .mediahub-episode-content__episode-participants {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
  .mediahub-episode-content__episode-participants a:not([class]) {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-episode-content__episode-participants span {
  padding-right: 1ch;
}
.mediahub-episode-content__episode--article .mediahub-episode-content__episode-participants span {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  font-style: italic;
}
@media screen and (min-width: 1216px) {
  .mediahub-episode-content__episode--article .mediahub-episode-content__episode-participants {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    color: #E9E8EB;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-participants {
  padding: 1rem 0;
}
.mediahub-episode-content__episode--video .mediahub-episode-content__episode-participants {
  padding: 1rem 1.5rem;
}
.mediahub-episode-content__episode-date {
  grid-area: date;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  display: flex;
  align-items: flex-end;
}
@media screen and (min-width: 1216px) {
  .mediahub-episode-content__episode-date {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-episode-content__episode--article .mediahub-episode-content__episode-date {
  padding-left: 2rem;
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-date {
  padding: 1rem 0;
}
.mediahub-episode-content__episode--video .mediahub-episode-content__episode-date {
  padding-left: 1.5rem;
}
.mediahub-episode-content__episode-duration {
  grid-area: duration;
  margin-left: 2rem;
  display: flex;
  align-items: flex-end;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}
@media screen and (min-width: 1216px) {
  .mediahub-episode-content__episode-duration {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-episode-content__episode--article .mediahub-episode-content__episode-duration {
  display: none;
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-duration {
  padding: 1rem 0;
}
.mediahub-episode-content__episode-cta {
  grid-area: cta;
  margin: auto 0 0 auto;
}
.mediahub-episode-content__episode--article .mediahub-episode-content__episode-cta {
  display: none;
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-cta {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: #000000;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-cta::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 2rem;
  transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-cta:hover::before {
  left: -0.5rem;
  right: -0.5rem;
  background-color: #E9E8EB;
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-cta::after {
  content: "";
  margin-left: 0.7rem;
  width: 0.8rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: left center;
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-cta::after {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-listen.svg);
}
.mediahub-episode-content__episode--video .mediahub-episode-content__episode-cta {
  display: none;
}
.is-mobile-detected .mediahub-episode-content__episode-cta--desktop,
.is-tablet-detected .mediahub-episode-content__episode-cta--desktop {
  display: none;
}
.is-desktop-detected .mediahub-episode-content__episode-cta--mobile {
  display: none;
}
.mediahub-episode-content__episode-card {
  background-color: #222222;
  border-radius: 0.5rem;
  overflow: hidden;
  display: none;
  width: 9.5rem;
  height: 9.5rem;
  position: absolute;
  z-index: 1;
  top: 1.5rem;
  left: 1.5rem;
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-card {
  display: block;
}
@media screen and (min-width: 1024px) {
  .mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-card {
    width: 20rem;
    height: 20rem;
  }
}
.mediahub-episode-content__episode-card-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: transform 200ms ease-out;
}
.mediahub-episode-content__episode-card-bg {
  position: relative;
  background-position: center;
  background-size: auto 100%;
  transition: background-size 300ms ease-out;
  width: 100%;
  height: 100%;
}
.mediahub-episode-content__episode-card-bg::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mediahub-episode-content__episode--article .mediahub-episode-content__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-episode-content__episode--article .mediahub-episode-content__episode-card-bg,
  h1.mediahub-episode-content__episode--article .mediahub-episode-content__episode-card-bg,
  h2.mediahub-episode-content__episode--article .mediahub-episode-content__episode-card-bg,
  h3.mediahub-episode-content__episode--article .mediahub-episode-content__episode-card-bg {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1.mediahub-episode-content__episode--article .mediahub-episode-content__episode-card-bg {
    font-weight: 800;
    color: white;
  }
  .mediahub-episode-content__episode--article .mediahub-episode-content__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 3.6rem;
    content: "artikel";
  }
}
.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-card-bg,
  h1.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-card-bg,
  h2.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-card-bg,
  h3.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-card-bg {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1.mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-card-bg {
    font-weight: 800;
    color: white;
  }
  .mediahub-episode-content__episode--podcast .mediahub-episode-content__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 3.3rem;
    content: "podcast";
  }
}
.mediahub-episode-content__episode--video .mediahub-episode-content__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
@media screen and (min-width: 1024px) {
  .mediahub-episode-content__episode--video .mediahub-episode-content__episode-card-bg,
  h1.mediahub-episode-content__episode--video .mediahub-episode-content__episode-card-bg,
  h2.mediahub-episode-content__episode--video .mediahub-episode-content__episode-card-bg,
  h3.mediahub-episode-content__episode--video .mediahub-episode-content__episode-card-bg {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1.mediahub-episode-content__episode--video .mediahub-episode-content__episode-card-bg {
    font-weight: 800;
    color: white;
  }
  .mediahub-episode-content__episode--video .mediahub-episode-content__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 4.2rem;
    content: "video";
  }
}
