@import (reference) "./_imports";

.mediahub-episodes-grid {
  .mediahub-container();
}

.mediahub-episodes-grid__title {
  .mediahub-header-font(2.8rem, 2rem, 3.2rem);
  margin-bottom: 2rem;

  @media @sm {
    .mediahub-header-font(5rem, 2rem, 6rem);
  }
}

.mediahub-dropdown {
  .mediahub-base-font(1.4rem);
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  @media @xl {
    margin-bottom: 2.5rem;
  }
}

.mediahub-dropdown__prefix {
  .mediahub-base-font(1.6rem);
  font-weight: bold;
  margin-right: 2rem;
  white-space: nowrap;
  display: none;

  @media @sm {
    display: block;
  }
}

.mediahub-dropdown__select {
  cursor: pointer;
  user-select: none;
  position: relative;
  height: 4.6rem;
  background: @mediahub-color-black-600;
  color: @ds-white;
  border: 0.1rem solid @ds-white;
  border-radius: 2.3rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  width: 100%;
  max-width: 40rem;

  &::before {
    content: "";
    position: absolute;
    top: calc(~'50% - 0.7rem');
    right: 2rem;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border: solid @ds-white;
    border-width: 0 0.2rem 0.2rem 0;

    .mediahub-dropdown--open & {
      transform: rotate(-135deg);
      top: calc(~'50% - 0.2rem');
    }
  }
}

.mediahub-dropdown__selected {
  font-weight: bold;
}

.mediahub-dropdown__options {
  display: none;
  position: absolute;
  z-index: 10;
  top: 5rem;
  left: 0;
  right: 0;
  border: 0.1rem solid @ds-white;
  border-radius: 2.3rem;
  overflow: hidden;
  color: @mediahub-color-lightgrey;
  background: @mediahub-color-black-600;

  .mediahub-dropdown--open & {
    display: block;
  }
}

.mediahub-dropdown__option {
  height: 4.6rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;

  &--selected {
    font-weight: bold;
  }

  &:hover {
    background: @mediahub-color-black-650;
  }
}

.mediahub-episodes-grid__episodes {
  @media @xl {
    display: grid;
    grid-template-columns: repeat(4, 22.8rem);
    grid-template-rows: repeat(auto-fill, 36rem);
    column-gap: ~"calc((100% - 4 * 22.8rem) / 3)";
    row-gap: 2.4rem;
  }

  @media @xxl {
    grid-template-columns: repeat(5, 22.8rem);
    column-gap: ~"calc((100% - 5 * 22.8rem) / 4)";
  }
}

.mediahub-episodes-grid__episode {
  cursor: pointer;
  display: grid;
  grid-template-areas: "title" "text" "date";
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  position: relative;
  min-height: 14.5rem;
  background-color: @mediahub-color-black-650;
  padding: 1.5rem 1.5rem 1.5rem 14.5rem;
  margin-bottom: 1rem;
  border-radius: 1rem;

  &:hover {
    background-color: @mediahub-color-black-700;
    
    .mediahub-episodes-grid__episode-card {
      .mediahub-card--hover();
    }
  }

  @media @xl {
    grid-template-areas: "title title" "date duration";
    grid-template-rows: auto 1fr;
    padding: 23.5rem 1.5rem 2.5rem;
    height: 36rem;
    margin-bottom: 0;
  }
}

.mediahub-episodes-grid__episode-title {
  grid-area: title;
  .mediahub-header-font(1.6rem, 0.5rem);
  .mediahub-lines-of-text(2);
}

.mediahub-episodes-grid__episode-text {
  grid-area: text;
  .mediahub-base-font-light-gray(1.2rem);
  .mediahub-lines-of-text(2);

  @media @xl {
    display: none;
  }
}

.mediahub-episodes-grid__episode-date {
  grid-area: date;
  .mediahub-base-font-gray(1.2rem);
  display: flex;
  align-items: flex-end;

  @media @xl {
    font-size: 1.4rem;
  }
}

.mediahub-episodes-grid__episode-duration {
  grid-area: duration;
  display: none;

  @media @xl {
    .mediahub-base-font-gray(1.4rem);
    display: flex;
    align-items: flex-end;
  }
}

.mediahub-episodes-grid__episode-card {
  .mediahub-card();
  width: 11.4rem;
  height: 11.4rem;
  position: absolute;
  z-index: 1;
  top: 1.5rem;
  left: 1.5rem;

  @media @xl {
    width: 20rem;
    height: 20rem;
  }
}

.mediahub-episodes-grid__episode-card-logo {
  .mediahub-card-logo();
}

.mediahub-episodes-grid__episode-card-bg {
  .mediahub-card-bg();

  .mediahub-episodes-grid__episode--article & {
    .mediahub-card-type-article--sm();

    @media @xl {
      .mediahub-card-type-article--lg();
    }
  }

  .mediahub-episodes-grid__episode--podcast & {
    .mediahub-card-type-podcast--sm();

    @media @xl {
      .mediahub-card-type-podcast--lg();
    }
  }

  .mediahub-episodes-grid__episode--video & {
    .mediahub-card-type-video--sm();

    @media @xl {
      .mediahub-card-type-video--lg();
    }
  }
}
