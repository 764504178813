.mediahub-article-text {
  padding: 0 1.5rem;
  max-width: 57rem;
  margin: 0 auto;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  color: white;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  margin-bottom: 3rem;
}
@media screen and (min-width: 768px) {
  .mediahub-article-text {
    padding: 0;
    max-width: 60rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-article-text {
    max-width: 64rem;
  }
}
.mediahub-article-text p {
  margin-bottom: 2rem;
}
.mediahub-article-text h2,
h1.mediahub-article-text h2,
h2.mediahub-article-text h2,
h3.mediahub-article-text h2 {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 2.4rem;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 1rem;
}
h1.mediahub-article-text h2 {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 768px) {
  .mediahub-article-text h2,
  h1.mediahub-article-text h2,
  h2.mediahub-article-text h2,
  h3.mediahub-article-text h2 {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 3.2rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 3.8rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 1.5rem;
  }
  h1.mediahub-article-text h2 {
    font-weight: 800;
    color: white;
  }
}
.mediahub-article-text h3,
h1.mediahub-article-text h3,
h2.mediahub-article-text h3,
h3.mediahub-article-text h3 {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.7rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 2rem;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 1rem;
}
h1.mediahub-article-text h3 {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 768px) {
  .mediahub-article-text h3,
  h1.mediahub-article-text h3,
  h2.mediahub-article-text h3,
  h3.mediahub-article-text h3 {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 2.4rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 3rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 1.5rem;
  }
  h1.mediahub-article-text h3 {
    font-weight: 800;
    color: white;
  }
}
