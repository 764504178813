.mediahub-profile {
  padding: 0 1.5rem;
  margin: 0 auto 5rem;
  max-width: 102.4rem;
}
@media screen and (min-width: 1440px) {
  .mediahub-profile {
    max-width: 128rem;
  }
}
.mediahub-profile {
  position: relative;
  display: grid;
  grid-template-areas: "image" "name" "text";
  row-gap: 1.5rem;
}
@media screen and (min-width: 768px) {
  .mediahub-profile {
    grid-template-areas: "name name" "text image";
    grid-template-columns: 1fr minmax(35rem, 1fr);
    grid-template-rows: auto 1fr;
    column-gap: 3rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-profile {
    grid-template-areas: "name image" "text image";
    grid-template-columns: 1fr minmax(auto, 53rem);
    column-gap: 8rem;
  }
}
h1.mediahub-profile__name {
  grid-area: name;
}
h1.mediahub-profile__name,
h1h1.mediahub-profile__name,
h2h1.mediahub-profile__name,
h3h1.mediahub-profile__name {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 3.2rem;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 1rem;
}
h1h1.mediahub-profile__name {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 1216px) {
  h1.mediahub-profile__name,
  h1h1.mediahub-profile__name,
  h2h1.mediahub-profile__name,
  h3h1.mediahub-profile__name {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 5rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 6rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 1rem;
  }
  h1h1.mediahub-profile__name {
    font-weight: 800;
    color: white;
  }
}
.mediahub-profile__text {
  grid-area: text;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}
@media screen and (min-width: 1216px) {
  .mediahub-profile__text {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.6rem;
    color: #E9E8EB;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
  }
}
.mediahub-profile__image {
  grid-area: image;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.mediahub-profile__image span {
  width: 100%;
  position: relative;
  z-index: 0;
  line-height: 0;
}
@media screen and (min-width: 768px) {
  .mediahub-profile__image span {
    margin-top: -4rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-profile__image span {
    margin-top: initial;
  }
}
.mediahub-profile__image span::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 4rem;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3rem;
  background-color: #373341;
}
.mediahub-profile__image img {
  width: 100%;
  position: relative;
  z-index: 1;
  max-width: 100%;
  border-radius: 0 0 3rem 3rem;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  .mediahub-profile__image img {
    width: 100%;
  }
}
