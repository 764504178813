@import (reference) "./_imports";

.mediahub-episodes-latest {
  .mediahub-container();

  @media @lg {
    display: grid;
    grid-template-areas: "description subtitle" "description episodes";
    grid-template-columns: 1fr 48rem;
    grid-column-gap: 5rem;
  }

  @media @xxl {
    grid-template-columns: 1fr 60rem;
  }
}

.mediahub-episodes-latest__title {
  .mediahub-header-font(2.8rem, 2rem, 3.2rem);

  @media @lg {
    .mediahub-header-font(5rem, 2rem, 6rem);
  }
}

.mediahub-episodes-latest__subtitle {
  grid-area: subtitle;
  .mediahub-header-font(1.6rem, 1rem);

  @media @lg {
    text-align: right;
  }
}

.mediahub-episodes-latest__text {
  .mediahub-base-font-light-gray(1.6rem);
}

.mediahub-episodes-latest__description {
  margin-bottom: 3rem;
  grid-area: description;

  @media @lg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 48rem;
  }
}

.mediahub-episodes-latest__episodes {
  grid-area: episodes;

  @media @sm {
    display: flex;
    justify-content: space-between;
  }

  @media @lg {
    flex-direction: column;
  }
}

.mediahub-episodes-latest__episode {
  display: grid;
  grid-template-areas: "title title" "text text" "date cta";
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto 1fr;
  position: relative;
  min-height: 14.5rem;
  background-color: @mediahub-color-black-650;
  padding: 1.5rem 1.5rem 1.5rem 14.5rem;
  margin-bottom: 1rem;
  border-radius: 1rem;

  &:hover {
    background-color: @mediahub-color-black-700;
    
    .mediahub-episodes-latest__episode-card {
      .mediahub-card--hover();
    }
  }

  @media @sm {
    flex: 0 0 calc(~"50% - 0.5rem");
    margin-bottom: 0;
  }

  @media @lg {
    margin-bottom: 1.5rem;
    flex: 1;
  }

  @media @xxl {
    padding: 1rem 1.5rem 1.3rem 14.5rem;
    grid-template-areas: "title title cta" "text text cta" "date duration cta";
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto 1fr;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.mediahub-episodes-latest__episode-title {
  grid-area: title;
  .mediahub-header-font(1.6rem, 0.3rem);
  .mediahub-lines-of-text(2);
}

.mediahub-episodes-latest__episode-text {
  grid-area: text;
  .mediahub-base-font-light-gray(1.4rem);
  .mediahub-lines-of-text(1);
}

.mediahub-episodes-latest__episode-date {
  grid-area: date;
  .mediahub-base-font-gray(1.2rem);
  display: flex;
  align-items: flex-end;
  padding-bottom: 0.9rem;

  @media @xxl {
    padding-bottom: 0;
    margin-right: 2rem;
    font-size: 1.2rem;
    align-items: flex-end;
  }
}

.mediahub-episodes-latest__episode-duration {
  grid-area: duration;
  display: none;

  @media @xxl {
    .mediahub-base-font-gray(1.2rem);
    display: flex;
    align-items: flex-end;
  }
}

.mediahub-episodes-latest__episode-cta {
  grid-area: cta;
  margin-top: auto;

  @media @xxl {
    margin: auto auto auto 1rem;
  }

  .mediahub-episodes-latest__episode--article & {
    .mediahub-cta-article();
  }

  .mediahub-episodes-latest__episode--podcast & {
    .mediahub-cta-podcast();
  }

  .mediahub-episodes-latest__episode--video & {
    .mediahub-cta-video();
  }

  .mediahub-episodes-latest__episode--article &--mobile,
  .mediahub-episodes-latest__episode--podcast &--mobile,
  .mediahub-episodes-latest__episode--video &--mobile {
    display: flex;

    @media @lg {
      display: none;
    }
  }

  .mediahub-episodes-latest__episode--article &--desktop,
  .mediahub-episodes-latest__episode--podcast &--desktop,
  .mediahub-episodes-latest__episode--video &--desktop {
    display: none;
    
    @media @lg {
      display: flex;
    }
  }
}

.mediahub-episodes-latest__episode-card {
  .mediahub-card();
  width: 11.4rem;
  height: 11.4rem;
  position: absolute;
  z-index: 1;
  top: 1.5rem;
  left: 1.5rem;
}

.mediahub-episodes-latest__episode-card-logo {
  .mediahub-card-logo();
}

.mediahub-episodes-latest__episode-card-bg {
  .mediahub-card-bg();

  .mediahub-episodes-latest__episode--article & {
    .mediahub-card-type-article--sm();
  }

  .mediahub-episodes-latest__episode--podcast & {
    .mediahub-card-type-podcast--sm();
  }

  .mediahub-episodes-latest__episode--video & {
    .mediahub-card-type-video--sm();
  }
}
