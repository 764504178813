@import (reference) "./../../../_imports";
@import (reference) "../Colors/_colors";

.mediahub-cta() {
  position: relative;
  z-index: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: 1;
  color: @ds-black;
  font-weight: 700;
  padding: 0 2rem;
  white-space: nowrap;
  min-width: 8.3rem;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: @ds-white;
    border-radius: 2rem;
    transition: left 200ms ease-out, right 200ms ease-out, background-color 200ms ease-out;
  }

  &:hover::before {
    left: -0.5rem;
    right: -0.5rem;
    background-color: @mediahub-color-lightgrey;
  }
}

.mediahub-episode-cta() {
  .mediahub-cta();

  &::after {
    content: "";
    margin-left: 0.7rem;
    width: 0.8rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.mediahub-cta-primary() {
  .mediahub-cta();

  &::before {
    background-color: @ds-yellow;
  }

  &:hover::before {
    background-color: @ds-yellow-600;
  }
}

.mediahub-cta-article() {
  .mediahub-episode-cta();

  &::after {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-read.svg);
  }
}

.mediahub-cta-podcast() {
  .mediahub-episode-cta();

  &::after {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-listen.svg);
  }
}

.mediahub-cta-video() {
  .mediahub-episode-cta();

  &::after {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/icon-cta-play.svg);
  }
}
