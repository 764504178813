// Avalon styles
@import "../../_avalonComponents.less";

// Poker colors
@import (reference) "_colors.less";

.page-area {
  &.overlay-active {
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
  }
}
