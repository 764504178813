@import (reference) "./../../../_imports";
@import (reference) "../Colors/_colors";

.mediahub-base-font(@fontSize: 1.6rem, @color: @ds-white, @fontWeight: normal) {
  font: @font-primary;
  font-weight: @fontWeight;
  font-size: @fontSize;
  color: @color;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
}

.mediahub-base-font-light-gray(@fontSize: 1.6rem, @color: @mediahub-color-lightgrey, @fontWeight: normal) {
  .mediahub-base-font(@fontSize, @color, @fontWeight);
}

.mediahub-base-font-light-gray-bold(@fontSize: 1.6rem) {
  .mediahub-base-font(@fontSize, @mediahub-color-lightgrey, 700);
}

.mediahub-base-font-gray(@fontSize: 1.6rem) {
  .mediahub-base-font(@fontSize, @mediahub-color-grey);
}

.mediahub-header-font(@fontSize: 1.6rem, @marginBottom: initial, @lineHeight: 1.5) {
  &,
  h1&,
  h2&,
  h3& {
    font: @font-primary;
    font-size: @fontSize;
    font-weight: 700;
    color: @mediahub-color-lightgrey;
    line-height: @lineHeight;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: @marginBottom;
  }

  h1& {
    font-weight: 800;
    color: @ds-white;
  }
}

.mediahub-lines-of-text(@linesOfText: 1) {
  height: auto;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @linesOfText;
  -webkit-box-orient: vertical;
}

.mediahub-all-lines-text() {
  overflow: auto;
  max-height: auto;
  text-overflow: initial;
  display: block;
}
