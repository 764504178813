@import (reference) "../../AvalonComponents/Styles/Colors/_colors";

html.mode-edit .ds-regionspots {

  .scEmptyPlaceholder {
    height: 100%;
  }

  > div {
    position: relative;

    &::after {
      pointer-events: none;
      position: absolute;
      top: 0.7rem;
      left: 0.7rem;
      z-index: 1;
      font-size: 1.1rem;
      color: @ds-black;
      background: fade(@ds-white, 80%);
      padding: 0.4rem 0.5rem;
    }
  }

  &.ds-regionspots--variant-1 > div {
    &:nth-of-type(1)::after {
      content: "Regionspot 1: Mega";
    }

    &:nth-of-type(2)::after {
      content: "Regionspot 2: Small";
    }

    &:nth-of-type(3)::after {
      content: "Regionspot 3: Small";
    }

    &:nth-of-type(4)::after {
      content: "Regionspot 4: Medium";
    }

    &:nth-of-type(5)::after {
      content: "Regionspot 5: Medium";
    }

    &:nth-of-type(6)::after {
      content: "Regionspot 6: Small";
    }

    &:nth-of-type(7)::after {
      content: "Regionspot 7: Small";
    }
  }

  &.ds-regionspots--variant-2 > div {
    &:nth-of-type(1)::after {
      content: "Regionspot 1: Small";
    }

    &:nth-of-type(2)::after {
      content: "Regionspot 2: Small";
    }

    &:nth-of-type(3)::after {
      content: "Regionspot 3: Medium";
    }

    &:nth-of-type(4)::after {
      content: "Regionspot 4: Medium";
    }

    &:nth-of-type(5)::after {
      content: "Regionspot 5: Small";
    }

    &:nth-of-type(6)::after {
      content: "Regionspot 6: Small";
    }
  }

  &.ds-regionspots--variant-3 > div {
    &:nth-of-type(1)::after {
      content: "Regionspot 1: Small";
    }

    &:nth-of-type(2)::after {
      content: "Regionspot 2: Small";
    }

    &:nth-of-type(3)::after {
      content: "Regionspot 3: Medium";
    }
  }

  &.ds-regionspots--variant-4 > div {
    &:nth-of-type(1)::after {
      content: "Regionspot 1: Mega";
    }
  }
}
