@import (reference) "./_imports";

.mediahub-series-highlight {
  .mediahub-container();
  padding: 0;
}

.mediahub-series-highlight__title {
  .mediahub-header-font(1.6rem, 1rem);
  padding: 0 1.5rem;

  @media @lg {
    .mediahub-header-font(2.4rem, 2rem);
  }

  &--show-more {
    display: none;
    .mediahub-base-font(1.6rem, @mediahub-color-grey);
    line-height: 4.4rem;
    font-weight: 400;
    float: right;

    @media @lg {
      display: block;
    }
  }
}

@media @lg {
  .mediahub-series-highlight[data-episodes-in-series="0"],
  .mediahub-series-highlight[data-episodes-in-series="1"],
  .mediahub-series-highlight[data-episodes-in-series="2"],
  .mediahub-series-highlight[data-episodes-in-series="3"],
  .mediahub-series-highlight[data-episodes-in-series="4"] {
    .mediahub-series-highlight__title--show-more {
      display: none;
    }
  }
}

@media @xxl {
  .mediahub-series-highlight[data-episodes-in-series="0"],
  .mediahub-series-highlight[data-episodes-in-series="1"],
  .mediahub-series-highlight[data-episodes-in-series="2"],
  .mediahub-series-highlight[data-episodes-in-series="3"],
  .mediahub-series-highlight[data-episodes-in-series="4"],
  .mediahub-series-highlight[data-episodes-in-series="5"] {
    .mediahub-series-highlight__title--show-more {
      display: none;
    }
  }
}

.mediahub-series-highlight__contents {
  white-space: nowrap;
  overflow: hidden;
  height: 17rem;

  @media @lg {
    overflow: hidden;
    height: auto;
    white-space: normal;
  }
}

.mediahub-series-highlight__contents-inner {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 1rem 5rem 1.5rem;

  @media @lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
    display: grid;
    grid-template-columns: repeat(4, 23.5rem);
    grid-template-rows: repeat(auto-fill, 35.9rem);
    gap: ~"calc((100% - 4 * 23.5rem) / 3)";
    padding: 0 1.5rem;
  }

  @media @xxl {
    grid-template-columns: repeat(5, 23.5rem);
    gap: ~"calc((100% - 5 * 23.5rem) / 4)";
  }
}

.mediahub-series-highlight__episode {
  white-space: normal;
  position: relative;
  display: inline-grid;
  margin-right: 1rem;
  grid-template-columns: 14.5rem;
  grid-template-rows: 14.5rem 1fr;
  row-gap: 0.5rem;
  grid-template-areas: "card" "title";

  &:hover {
    background-color: @mediahub-color-black-700;
    
    .mediahub-series-highlight__episode-card {
      .mediahub-card--hover();
    }
  }

  @media @sm {
    grid-template-rows: 14.5rem 2.7rem;
    row-gap: 0.8rem;
  }

  @media @lg {
    height: 35.9rem;
    padding: 1.75rem 1.75rem 2rem;
    margin-right: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 21rem auto 1fr;
    grid-template-areas: "card card" "title title" "date duration";
    background-color: @mediahub-color-black-650;
    border-radius: 1rem;
    overflow: hidden;

    &:nth-child(1n + 5) {
      display: none;
    }
  }

  @media @xxl {
    &:nth-child(1n + 5) {
      display: inline-grid;
    }

    &:nth-child(1n + 6) {
      display: none;
    }
  }
}

.mediahub-series-highlight__episode-show-more {
  position: relative;
  height: 14.5rem;
  z-index: 1;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: @mediahub-color-black-700;

  &[data-has-eight-or-less-episodes] {
    display: none;
  }

  @media @lg {
    display: none;
  }
}

.mediahub-series-highlight__episode-show-more-inner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  .mediahub-header-font();
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.8rem;
    line-height: 1;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/arrow-show-more.svg);
  }
}

h3.mediahub-series-highlight__episode-title {
  grid-area: title;
  .mediahub-base-font(1.2rem);
  .mediahub-lines-of-text(1);
  padding: 0 0.2rem;

  @media @lg {
    .mediahub-header-font(1.6rem);
    .mediahub-lines-of-text(2);
    padding: 0;
  }
}

.mediahub-series-highlight__episode-text {
  display: none;
}

.mediahub-series-highlight__episode-date {
  display: none;

  @media @lg {
    grid-area: date;
    .mediahub-base-font-gray(1.4rem);
    display: flex;
    align-items: flex-end;
  }
}

.mediahub-series-highlight__episode-duration {
  grid-area: duration;
  display: none;

  @media @lg {
    .mediahub-base-font-gray(1.4rem);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.mediahub-series-highlight__episode-cta {
  display: none;
}

.mediahub-series-highlight__episode-card {
  .mediahub-card();
  grid-area: card;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.mediahub-series-highlight__episode-card-logo {
  .mediahub-card-logo();
}

.mediahub-series-highlight__episode-card-bg {
  .mediahub-card-bg();

  .mediahub-series-highlight__episode--article & {
    .mediahub-card-type-article--sm();

    @media @lg {
      .mediahub-card-type-article--lg();
    }
  }

  .mediahub-series-highlight__episode--podcast & {
    .mediahub-card-type-podcast--sm();

    @media @lg {
      .mediahub-card-type-podcast--lg();
    }
  }

  .mediahub-series-highlight__episode--video & {
    .mediahub-card-type-video--sm();

    @media @lg {
      .mediahub-card-type-video--lg();
    }
  }
}
