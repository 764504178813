@import (reference) "./_imports";

.mediahub-profile {
  padding: 0 1.5rem;
  margin: 0 auto 5rem;
  max-width: 102.4rem;

  @media @xxl {
    max-width: 128rem;
  }
}

.mediahub-profile {
  position: relative;
  display: grid;
  grid-template-areas: "image" "name" "text";
  row-gap: 1.5rem;

  @media @sm {
    grid-template-areas: "name name" "text image";
    grid-template-columns: 1fr minmax(35rem, 1fr);
    grid-template-rows: auto 1fr;
    column-gap: 3rem;
  }

  @media @xxl {
    grid-template-areas: "name image" "text image";
    grid-template-columns: 1fr minmax(auto, 53rem);
    column-gap: 8rem;
  }
}

h1.mediahub-profile__name {
  grid-area: name;
  .mediahub-header-font(2.8rem, 1rem, 3.2rem);

  @media @xl {
    .mediahub-header-font(5rem, 1rem, 6rem);
  }
}

.mediahub-profile__text {
  grid-area: text;
  .mediahub-base-font-light-gray(1.4rem);

  @media @xl {
    .mediahub-base-font-light-gray(1.6rem);
  }
}

.mediahub-profile__image {
  grid-area: image;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  span {
    width: 100%;
    position: relative;
    z-index: 0;
    line-height: 0;

    @media @sm {
      margin-top: -4rem;
    }

    @media @xxl {
      margin-top: initial;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 4rem;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 3rem;
      background-color: @mediahub-color-black-700;
    }
  }

  img {
    width: 100%;
    position: relative;
    z-index: 1;
    max-width: 100%;
    border-radius: 0 0 3rem 3rem;
    overflow: hidden;

    @media @lg {
      width: 100%;
    }
  }
}
