.mediahub-episodes-grid {
  padding: 0 1.5rem;
  max-width: 102.4rem;
  margin: 0 auto 3rem;
}
@media screen and (min-width: 1440px) {
  .mediahub-episodes-grid {
    max-width: 128rem;
    margin: 0 auto 5rem;
  }
}
.mediahub-episodes-grid__title {
  margin-bottom: 2rem;
}
.mediahub-episodes-grid__title,
h1.mediahub-episodes-grid__title,
h2.mediahub-episodes-grid__title,
h3.mediahub-episodes-grid__title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 2.8rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 3.2rem;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 2rem;
}
h1.mediahub-episodes-grid__title {
  font-weight: 800;
  color: white;
}
@media screen and (min-width: 768px) {
  .mediahub-episodes-grid__title,
  h1.mediahub-episodes-grid__title,
  h2.mediahub-episodes-grid__title,
  h3.mediahub-episodes-grid__title {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 5rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 6rem;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: 2rem;
  }
  h1.mediahub-episodes-grid__title {
    font-weight: 800;
    color: white;
  }
}
.mediahub-dropdown {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.4rem;
  color: white;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1216px) {
  .mediahub-dropdown {
    margin-bottom: 2.5rem;
  }
}
.mediahub-dropdown__prefix {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  color: white;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  font-weight: bold;
  margin-right: 2rem;
  white-space: nowrap;
  display: none;
}
@media screen and (min-width: 768px) {
  .mediahub-dropdown__prefix {
    display: block;
  }
}
.mediahub-dropdown__select {
  cursor: pointer;
  user-select: none;
  position: relative;
  height: 4.6rem;
  background: #1C1A21;
  color: white;
  border: 0.1rem solid white;
  border-radius: 2.3rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  width: 100%;
  max-width: 40rem;
}
.mediahub-dropdown__select::before {
  content: "";
  position: absolute;
  top: calc(50% - 0.7rem);
  right: 2rem;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border: solid white;
  border-width: 0 0.2rem 0.2rem 0;
}
.mediahub-dropdown--open .mediahub-dropdown__select::before {
  transform: rotate(-135deg);
  top: calc(50% - 0.2rem);
}
.mediahub-dropdown__selected {
  font-weight: bold;
}
.mediahub-dropdown__options {
  display: none;
  position: absolute;
  z-index: 10;
  top: 5rem;
  left: 0;
  right: 0;
  border: 0.1rem solid white;
  border-radius: 2.3rem;
  overflow: hidden;
  color: #E9E8EB;
  background: #1C1A21;
}
.mediahub-dropdown--open .mediahub-dropdown__options {
  display: block;
}
.mediahub-dropdown__option {
  height: 4.6rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
}
.mediahub-dropdown__option--selected {
  font-weight: bold;
}
.mediahub-dropdown__option:hover {
  background: #2D2A35;
}
@media screen and (min-width: 1216px) {
  .mediahub-episodes-grid__episodes {
    display: grid;
    grid-template-columns: repeat(4, 22.8rem);
    grid-template-rows: repeat(auto-fill, 36rem);
    column-gap: calc((100% - 4 * 22.8rem) / 3);
    row-gap: 2.4rem;
  }
}
@media screen and (min-width: 1440px) {
  .mediahub-episodes-grid__episodes {
    grid-template-columns: repeat(5, 22.8rem);
    column-gap: calc((100% - 5 * 22.8rem) / 4);
  }
}
.mediahub-episodes-grid__episode {
  cursor: pointer;
  display: grid;
  grid-template-areas: "title" "text" "date";
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  position: relative;
  min-height: 14.5rem;
  background-color: #2D2A35;
  padding: 1.5rem 1.5rem 1.5rem 14.5rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
}
.mediahub-episodes-grid__episode:hover {
  background-color: #373341;
}
.mediahub-episodes-grid__episode:hover .mediahub-episodes-grid__episode-card :first-child {
  transform: scale(96%);
}
.mediahub-episodes-grid__episode:hover .mediahub-episodes-grid__episode-card :last-child {
  background-size: auto 103%;
}
@media screen and (min-width: 1216px) {
  .mediahub-episodes-grid__episode {
    grid-template-areas: "title title" "date duration";
    grid-template-rows: auto 1fr;
    padding: 23.5rem 1.5rem 2.5rem;
    height: 36rem;
    margin-bottom: 0;
  }
}
.mediahub-episodes-grid__episode-title {
  grid-area: title;
  height: auto;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.mediahub-episodes-grid__episode-title,
h1.mediahub-episodes-grid__episode-title,
h2.mediahub-episodes-grid__episode-title,
h3.mediahub-episodes-grid__episode-title {
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  color: #E9E8EB;
  line-height: 1.5;
  overflow-wrap: initial;
  text-transform: none;
  margin-top: initial;
  margin-bottom: 0.5rem;
}
h1.mediahub-episodes-grid__episode-title {
  font-weight: 800;
  color: white;
}
.mediahub-episodes-grid__episode-text {
  grid-area: text;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #E9E8EB;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  height: auto;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media screen and (min-width: 1216px) {
  .mediahub-episodes-grid__episode-text {
    display: none;
  }
}
.mediahub-episodes-grid__episode-date {
  grid-area: date;
  font: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #9D97AA;
  line-height: 1.5;
  text-transform: none;
  overflow-wrap: initial;
  margin-top: initial;
  margin-bottom: initial;
  display: flex;
  align-items: flex-end;
}
@media screen and (min-width: 1216px) {
  .mediahub-episodes-grid__episode-date {
    font-size: 1.4rem;
  }
}
.mediahub-episodes-grid__episode-duration {
  grid-area: duration;
  display: none;
}
@media screen and (min-width: 1216px) {
  .mediahub-episodes-grid__episode-duration {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 1.4rem;
    color: #9D97AA;
    line-height: 1.5;
    text-transform: none;
    overflow-wrap: initial;
    margin-top: initial;
    margin-bottom: initial;
    display: flex;
    align-items: flex-end;
  }
}
.mediahub-episodes-grid__episode-card {
  background-color: #222222;
  border-radius: 0.5rem;
  overflow: hidden;
  width: 11.4rem;
  height: 11.4rem;
  position: absolute;
  z-index: 1;
  top: 1.5rem;
  left: 1.5rem;
}
@media screen and (min-width: 1216px) {
  .mediahub-episodes-grid__episode-card {
    width: 20rem;
    height: 20rem;
  }
}
.mediahub-episodes-grid__episode-card-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: transform 200ms ease-out;
}
.mediahub-episodes-grid__episode-card-bg {
  position: relative;
  background-position: center;
  background-size: auto 100%;
  transition: background-size 300ms ease-out;
  width: 100%;
  height: 100%;
}
.mediahub-episodes-grid__episode-card-bg::before {
  content: "";
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mediahub-episodes-grid__episode--article .mediahub-episodes-grid__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
@media screen and (min-width: 1216px) {
  .mediahub-episodes-grid__episode--article .mediahub-episodes-grid__episode-card-bg,
  h1.mediahub-episodes-grid__episode--article .mediahub-episodes-grid__episode-card-bg,
  h2.mediahub-episodes-grid__episode--article .mediahub-episodes-grid__episode-card-bg,
  h3.mediahub-episodes-grid__episode--article .mediahub-episodes-grid__episode-card-bg {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1.mediahub-episodes-grid__episode--article .mediahub-episodes-grid__episode-card-bg {
    font-weight: 800;
    color: white;
  }
  .mediahub-episodes-grid__episode--article .mediahub-episodes-grid__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-article.svg);
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 3.6rem;
    content: "artikel";
  }
}
.mediahub-episodes-grid__episode--podcast .mediahub-episodes-grid__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
@media screen and (min-width: 1216px) {
  .mediahub-episodes-grid__episode--podcast .mediahub-episodes-grid__episode-card-bg,
  h1.mediahub-episodes-grid__episode--podcast .mediahub-episodes-grid__episode-card-bg,
  h2.mediahub-episodes-grid__episode--podcast .mediahub-episodes-grid__episode-card-bg,
  h3.mediahub-episodes-grid__episode--podcast .mediahub-episodes-grid__episode-card-bg {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1.mediahub-episodes-grid__episode--podcast .mediahub-episodes-grid__episode-card-bg {
    font-weight: 800;
    color: white;
  }
  .mediahub-episodes-grid__episode--podcast .mediahub-episodes-grid__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-podcast.svg);
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 3.3rem;
    content: "podcast";
  }
}
.mediahub-episodes-grid__episode--video .mediahub-episodes-grid__episode-card-bg::before {
  background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
  background-position: 0.9rem 0.9rem;
  background-size: auto 1.5rem;
}
@media screen and (min-width: 1216px) {
  .mediahub-episodes-grid__episode--video .mediahub-episodes-grid__episode-card-bg,
  h1.mediahub-episodes-grid__episode--video .mediahub-episodes-grid__episode-card-bg,
  h2.mediahub-episodes-grid__episode--video .mediahub-episodes-grid__episode-card-bg,
  h3.mediahub-episodes-grid__episode--video .mediahub-episodes-grid__episode-card-bg {
    font: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #E9E8EB;
    line-height: 1.5;
    overflow-wrap: initial;
    text-transform: none;
    margin-top: initial;
    margin-bottom: initial;
  }
  h1.mediahub-episodes-grid__episode--video .mediahub-episodes-grid__episode-card-bg {
    font-weight: 800;
    color: white;
  }
  .mediahub-episodes-grid__episode--video .mediahub-episodes-grid__episode-card-bg::before {
    background-image: url(/Components/DanskeSpil/Domain/AvalonComponents/Assets/Graphics/Mediahub/type-video.svg);
    background-position: 1.1rem 1rem;
    background-size: auto 1.8rem;
    line-height: 1;
    padding: 1rem 4.2rem;
    content: "video";
  }
}
